import React from "react";
import UserMenu from "../Menu/UserMenu";
import { IonCard, IonCardHeader, IonRow, IonCardTitle, IonLabel, IonCardContent, IonCol, IonIcon, IonText, IonItem } from "@ionic/react";
import { callOutline, briefcaseOutline, personCircleOutline } from "ionicons/icons";
import "./UserCard.scss"
import { IUser } from "../../Interface/User";

const UserCard: React.FC<any> = ({ user, getUserList }: {
  user: IUser
  getUserList: any
}) => {
  const isAdmin = user.roles.find((role: string) => role === "ROLE_ADMIN");
  const isCurrentConnectedUser = user.id === JSON.parse(localStorage.getItem('user')!).id;

  return (
    <IonCard className="user-card">
      <IonCardHeader>
        <IonRow className="ion-align-items-center ion-justify-content-between">
          {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}

          <IonCardTitle>
            <IonItem
              className="user-card__title"
              routerLink={`/utilisateur/${user.id}`}
              routerDirection="none"
              lines="none"
            >
              <IonLabel className="user-card__name">
                {user.firstname} {user.lastname}
              </IonLabel>
              <IonLabel className="user-card__email">{user.email}</IonLabel>
            </IonItem>
          </IonCardTitle>

          {!isCurrentConnectedUser && <UserMenu userId={user.id} isEnabled={user.enabled} getUserList={getUserList} />}
        </IonRow>
      </IonCardHeader>

      <IonCardContent>
        <IonRow>
          <IonCol>
            <IonRow className="ion-align-items-center">
              {user.phone && (
                <a
                  href={`tel:${user.phone}`}
                  className="user-card__phone"
                >
                  <IonIcon
                    icon={callOutline}
                    color="dark"
                    className="user-card-icon"
                  />
                  <IonText color="dark" className="user-card__phone__text">
                    {user.phone}
                  </IonText>
                </a>
              )}
              {user.position && (
                <>
                  <IonIcon
                    icon={briefcaseOutline}
                    color="dark"
                    className="user-card-icon"
                  />
                  <IonText color="dark" className="user-card__position__text">
                    {user.position}
                  </IonText>
                </>
              )}
            </IonRow>
          </IonCol>
          <IonCol>
            <IonRow className="ion-align-items-center ion-justify-content-end">
              {isAdmin && (
                <>
                  <IonIcon
                    icon={personCircleOutline}
                    color="warning"
                    className="user-card-icon"
                  />
                  <IonText color="dark" className="user-card__admin__text">
                    Administrateur
                  </IonText>
                </>
              )}
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default UserCard;
