import React from 'react';
import './CreateProject.scss';
import ProjectForm from '../../../components/Project/ProjectForm';
import { IonPage, IonHeader, IonToolbar, IonContent, IonGrid } from '@ionic/react';
import NavigationMenu from '../../../components/Header/NavigationMenu/NavigationMenu';
import TitleOptionSearch from '../../../components/Header/TitleOptionSearch/TitleOptionSearch';

const CreateProject: React.FC = () => {
  document.title = 'Créer un projet - Canreport'

  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu
            active="projets"
            backLabel="Projets"
            backLink="/projets"
          />
          <TitleOptionSearch
            title="Créer un projet"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <ProjectForm />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateProject;
