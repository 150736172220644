
const useFilter = (type: string, setOnChange?: any) => {
    const triggerFilter = (filterName: string, list: any) => {
        const updatedList = filterSelector(filterName, list);
        if (setOnChange) setOnChange(updatedList)
        return updatedList;
    };

    const filterSelector = (filterName: string, list: any) => {
        switch (type) {
            case "user":
                localStorage.setItem("userfilter", filterName);
                return userFilter(filterName, list)
            case "project":
                localStorage.setItem("projectfilter", filterName);
                return projectFilter(filterName, list)
            case "subproject":
                localStorage.setItem("subprojectfilter", filterName);
                return subProjectFilter(filterName, list)
            case "task":
                localStorage.setItem("taskfilter", filterName);
                return taskFilter(filterName, list)
        }
    };

    const userFilter = (selected: string | null, list: any) => {
        switch (selected) {
            case "all":
                return list.filter((user: any) => user.enabled);
            case "administrators":
                return list.filter(
                    (user: any) => user.enabled && user.roles.find((role: string) => role === "ROLE_ADMIN")
                );
            case "clients":
                return list.filter(
                    (user: any) => user.enabled && !user.roles.find((role: string) => role === "ROLE_ADMIN")
                );
            case "disable":
                return list.filter((user: any) => !user.enabled);
        }
    };

    const projectFilter = (selected: string | null, list: any) => {
        switch (selected) {
            case "all":
                return list.filter((project: any) => !project.archived);
            case "archive":
                return list.filter((project: any) => project.archived);
        }
    };

    const subProjectFilter = (selected: string | null, list: any) => {
        switch (selected) {
            case "all":
                return list;
            case "wip":
                return list.filter((subProject: any) => subProject.progress !== 0 || subProject.progress !== 100);
            case "done":
                return list.filter((subProject: any) => subProject.progress === 100);
        }
    };

    const taskFilter = (selected: string | null, list: any) => {
        switch (selected) {
            case "editedToday":
            case "all":
            case "today":
            case "in3Days":
            case "todo":
                return list;
        }
    };

    const options = (): any => {
        switch (type) {
            case "user":
                return userOptions
            case "project":
                return projectOptions
            case "subproject":
                return subProjectOptions
            case "task":
                return taskOptions
        }
    };

    return { triggerFilter, options: options() };
};

const userOptions = [
    {
        value: "all",
        label: "Tous les utilisateurs",
    },
    {
        value: "administrators",
        label: "Administrateurs seulement",
    },
    {
        value: "clients",
        label: "Clients seulement",
    },
    {
        value: "disable",
        label: "Utilisateurs désactivés",
    },
];

const projectOptions = [
    {
        value: "all",
        label: "Tous les projets",
    },
    {
        value: "archive",
        label: "Projets archivés",
    },
];

const subProjectOptions = [
    {
        value: "all",
        label: "Tous les sous-projets",
    },
    {
        value: "wip",
        label: "Sous-projets en cours",
    },
    {
        value: "done",
        label: "Sous-projets terminés",
    },
];

const taskOptions = [
    {
        value: "all",
        label: "Toutes les tâches",
    },
    {
        value: "today",
        label: "A faire aujourd'hui",
    },
    {
        value: "inProgress",
        label: "Reste à faire",
    },
    {
        value: 'editedToday',
        label: "Avancé aujourd'hui"
    }
];

export default useFilter;
