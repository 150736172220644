import React, {useState, useRef, useEffect} from "react";
import "./RecapTask.scss";
import {IonIcon, IonLabel, IonRow} from "@ionic/react";
import RecapList from "./List/RecapList";
import useLongPress from "../../hooks/useLongPress";
import {chevronDown, chevronForward} from "ionicons/icons";
import ReactTooltip from 'react-tooltip';

const RecapTask: React.FC<any> = ({ task, unfold = false, breadcrumb }: any) => {
  const [isActive, setIsActive] = useState(unfold);
  const [setActive, setActiveState] = useState(isActive ? 'active' : '');
  const [setHeight, setHeightState] = useState(isActive ? 'unset' : "0px");
  const [unfoldAll] = useState<boolean>(unfold);

  useEffect(() => {
    if(unfold && !unfoldAll && !isActive) {
      toggleAccordion();
    } else if(!unfold && isActive) toggleAccordion();
  }, [unfold]);

  const longPressEvent = useLongPress(() => {
    /*setUnfoldAll(!unfoldAll);
    toggleAccordion()*/
  }, toggleAccordion, {});

  const content = useRef(null);

  function toggleAccordion() {
    setIsActive(!isActive)
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(setActive === "active" ? "0px" : `unset`);
  }

  // @ts-ignore
  const toolTip = () => <ReactTooltip />;

  return <div key={task.id} className="accordion__section" style={{
    marginLeft: "16px",
    borderTop: 'solid 1px silver'
  }}>
    <IonRow
      className={`ion-align-items-center ion-justify-content-between task-card__header accordion ${setActive}`}
      {...longPressEvent}>
        <IonRow className="ion-align-items-center">
              <div>
                <IonLabel style={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: "18px",
                  lineHeight: "24px",
                  width: "24px",
                  marginRight: "12px",
                  verticalAlign: "super"
                }}>
                  {isActive ? <IonIcon slot="icon-only" icon={chevronDown} /> : <IonIcon slot="icon-only" icon={chevronForward} />}
                </IonLabel>
              </div>
              <IonLabel data-tip={breadcrumb} className="task-card__title" style={{
                marginRight: '10px',
                color: "white",
                fontSize: '20px',
                fontZeight: 500,
                lineHeight: '1.2',
                 }}>{task.hierarchicalNumber} - {task.taskLabel}</IonLabel>
        </IonRow>
      {/*<Chip task={task} />*/}
    </IonRow>

  <div
    ref={content}
    style={{ maxHeight: `${setHeight}` }}
    className="accordion__content"
  >
    {(unfoldAll || isActive) && <RecapList taskList={task.children} unfoldAll={unfold} breadcrumb={breadcrumb != "" ? breadcrumb + " / " + task.taskLabel : task.taskLabel}/>}
  </div>
    {toolTip()}
</div>
}

export default RecapTask;
