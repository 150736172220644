import {IonContent, IonHeader, IonPage, IonLabel, IonToolbar, IonGrid, useIonViewWillEnter} from "@ionic/react";
import React, {useState, useRef } from "react";
import "./ProjectList.scss";
import { projectService } from '../../../services';
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import AddFilter from "../../../components/Header/AddFilter/AddFilter";
import { addOutline } from "ionicons/icons";
import useFilter from "../../../hooks/useFilter";
import useSearch from "../../../hooks/useSearch";
import useDataFlow from "../../../hooks/useDataFlow";
import ProjectCard from "../../../components/Project/ProjectCard";

const List = ({ list, getProjectList }: any) => <>
  {list.map((project: any) => <ProjectCard key={project.id} project={project} getProjectList={getProjectList} />)}
</>

const ProjectList: React.FC = () => {
  document.title = `Projets - Canreport`
  const isMountedRef = useRef<boolean>(true);

  const [isLoading, setIsLoading] = useState(true);
  const [projectListData, setProjectListData] = useState([])
  const [projectListDisplayed, setProjectListDisplayed] = useState([])
  const [waitForAcces, setWaitForAcces] = useState(false)
  const { triggerSearch } = useSearch('project', setProjectListDisplayed)
  const { triggerFilter, options } = useFilter('project')
  const { initDataFlow, dataFlow } = useDataFlow('project', projectListData, [triggerFilter, triggerSearch])

  const onFilterChange = (text: string) => {
    dataFlow([
      text,
      localStorage.getItem('search')!
    ])
  }

  const onSearchChange = (text: string) => {
    dataFlow([
      localStorage.getItem('projectfilter')!,
      text
    ])
  }

  const getProjectList = () => {
      projectService.getList().then(response => {
          if(isMountedRef.current) {
              setProjectListData(response.data)
              const searchText = ''
              localStorage.setItem('search', searchText)
              initDataFlow([
                  localStorage.getItem('projectfilter')!,
                  searchText
              ], response.data)
              setIsLoading(false)
          }
      }).catch(e => {
          setIsLoading(false)
          setWaitForAcces(true)
      })
  }

  useIonViewWillEnter(() => {
      setTimeout(() => {
          if(!localStorage.getItem('hasAlreadyReloaded')) {
              window.location.reload();
              localStorage.setItem('hasAlreadyReloaded', 'true');
          }
      }, 100);
      getProjectList();
      return () => { isMountedRef.current = false };
  }, []);

  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu active="projets" />
          <TitleOptionSearch
            title="Projets"
            listCount={projectListDisplayed.length || "0"}
            onSearchChange={onSearchChange}
          />
          <AddFilter
            type="project"
            addLink="/projets/creer"
            addLabel="Créer un projet"
            addIcon={addOutline}
            onFilterChange={onFilterChange}
            options={options}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
            <List list={projectListDisplayed} getProjectList={getProjectList} />
          {/* <EmptyList list={projectListDisplayed} isSearching={isSearching} /> */}
          {projectListDisplayed.length === 0 && !isLoading && <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '150px',
          }}>
            <IonLabel style={{marginBottom: '20px'}}>
              Aucun projet
            </IonLabel>
          </div>}
          {waitForAcces && !isLoading && <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10px',
          }}>
            <IonLabel style={{marginBottom: '20px', textAlign: 'center'}}>
              <p>Vous n'êtes pas autorisé à accéder aux projets.</p>
              <p>Veuillez attendre qu'un administrateur vous donne les autorisations nécessaires.</p>
            </IonLabel>
          </div>}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ProjectList;
