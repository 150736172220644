import React, {useEffect, useState} from "react";
import {
    IonBadge,
    IonButton,
    IonModal,
    IonRow,
    IonText
} from "@ionic/react";
import EditRexModal from "./EditRexModal";
import {userService, ReturnOfExperienceService} from "../../../services";
import './ListRexModal.scss';

const ListRexModal: React.FC<any> = ({ isOpen = false, rexes = [], onDismiss, deleteRex, task = null, subProject, refreshRexes }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [editingRex, setEditingRex] = useState(null);

    const saveTask = (taskData: any) => {
        const action = editingRex !== null ? ReturnOfExperienceService.put : ReturnOfExperienceService.post;

        action({...taskData,
            user: `/api/users/${JSON.parse(localStorage.getItem('user')!).id}`,
            subProject: `/api/sub_projects/${subProject.id}`,
            taskUniqueNumber: task.uniqueNumber}).then(data => {
            setIsEditing(false);
            setEditingRex(null);
            refreshRexes(task);
        }).catch(e => {});
    };

    const editRex = (rex: any) => {
      setEditingRex(rex);
      setIsEditing(true);
    };

    return <IonModal isOpen={isOpen} onDidDismiss={onDismiss} cssClass={"list-rex-modal"}>
        {isEditing ? <EditRexModal onDismiss={() => setIsEditing(false)} saveTask={saveTask} rex={editingRex} subProject={subProject} /> : <>
        <IonRow className={'ion-justify-content-between ion-align-items-center'}>
                <p>Liste des commentaires</p>
                <IonButton onClick={() => setIsEditing(true)}>
                    Ajouter
                </IonButton>
            </IonRow>
            {rexes.map((rex: any) =>
                <div key={rex.id}>
                    <hr/>
                    <IonRow className={"ion-justify-content-between"}>
                        <div>
                            <IonBadge color="medium">{rex.category}</IonBadge>
                            <h5 className={"h4"}><IonText style={{ whiteSpace: "pre-wrap" }}>{rex.comment}</IonText></h5>
                            <p className={"h6"}>Par <b>{rex.user.fullName}</b> le <b>{rex.createdAt}</b></p>
                        </div>
                        <IonRow className={'ion-align-items-center'}>
                            {(userService.isAdmin() || userService.getUser().email === rex.user.email) && <a onClick={() => editRex(rex)}>Editer</a>}
                            {(userService.isAdmin() || userService.getUser().email === rex.user.email) && <a style={{ color: "red", textDecoration: 'underline', cursor: 'pointer', marginLeft: 10 }} onClick={() => deleteRex(rex)}>Supprimer</a>}
                        </IonRow>
                    </IonRow>
                    <div>
                        {rex.imageOne && <a target="_blank" rel="noopener noreferrer" href={rex.imageOne}><img src={rex.imageOne} height="40" alt="imageOne"/></a>}
                        {rex.imageTwo && <a target="_blank" href={rex.imageTwo} rel="noopener noreferrer"><img src={rex.imageTwo} height="40" alt="imageTwo"/></a>}
                        {rex.imageThree && <a target="_blank" href={rex.imageThree} rel="noopener noreferrer"><img src={rex.imageThree} height="40" alt="imageThree"/></a>}
                    </div>
                </div>
            )}
            <IonButton onClick={onDismiss}>Fermer</IonButton>
        </>}
    </IonModal>;
}

export default ListRexModal;
