import React from "react";
import { IonCheckbox, IonItem, IonText } from "@ionic/react";

const AccessRow: React.FC<any> = ({ booklet, getPermission, postPermission, patchPermission, removePermission }: any) => {

    const handleConsultationChange = () => {
        const permission = getPermission(booklet);

        if (!permission) {
            addAcces('consultation')
            return;
        }

        removeAcces(permission, 'consultation')
    }

    const getNewPermissionLabels = (permission: string, permissions: string[], type: boolean): string[] => {
        if(type) {
            if(permission === 'pointage' && !permissions.includes('consultation')) permissions.push('consultation');
            permissions.push(permission);
        } else {
            if(permission === 'consultation' && permissions.includes('pointage')) return [];
            permissions.splice(permissions.indexOf('pointage'));
        }

        return permissions;
    }

    const removeAcces = (permission: any, permissionLabel: string) => {
        const newPermissions = getNewPermissionLabels(permissionLabel, permission.permissions, false);

        if(newPermissions.length === 0) {
            removePermission(permission.id);
        } else {
            patchPermission(permission.id, booklet, newPermissions);
        }
    }

    const addAcces = (permissionLabel: any) => {
        const permission = getPermission(booklet);
        const newPermissions = getNewPermissionLabels(permissionLabel, permission ? permission.permissions : [], true);

        if(permission) {
            patchPermission(permission.id, booklet, newPermissions);
        } else {
            postPermission(booklet, newPermissions);
        }
    }

    const handlePointingChange = () => {
        const permission = getPermission(booklet);
        if (!permission || !permission.permissions.includes('pointage')) {
            addAcces('pointage')
            return
        }
        removeAcces(permission, 'pointage')
    }

    const perm = getPermission(booklet);

    return (
        <IonItem lines="none" key={booklet}>
            <IonText slot="start">{booklet}</IonText>
            <IonCheckbox
                slot="end"
                style={{ marginRight: "56px" }}
                checked={perm && perm.permissions.includes('consultation')}
                onClick={(e) => handleConsultationChange()}
            />
            <IonCheckbox
                slot="end"
                color="primary"
                checked={perm && perm.permissions.includes('pointage')}
                style={{ marginRight: "13px" }}
                onClick={(e) => handlePointingChange()}
            />
        </IonItem>)
    ;
}

export default AccessRow;
