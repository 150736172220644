import React, {useState, useMemo, useEffect} from "react";
import { IonCard, IonCardHeader, IonRow, IonCol, IonCardTitle, IonLabel, IonItem,  IonCardContent, IonToggle } from "@ionic/react";
import { permissionService } from "../../services";
import AccessRow from "./AccessRow";
import {useToast} from "../../contexts/ToastContext";

const AccessCard: React.FC<any> = ({ client, permission, projectId, subProjectId, bookletList, updatePermissions }: any) => {
    const [isDocumentationEnable, setIsDocumentationEnable] = useState(false);
    const [isProgressReportEnabled, setProgressReportEnabled] = useState(false);

    const [permissions, setPermissions] = useState(permission.permissions ?? []);
    const toast = useToast();

    useEffect(() => {
        setPermissions(permission.permissions ?? []);
        if(permission.permissions !== undefined) setIsDocumentationEnable(permission.permissions.some((perm: any) => perm.booklet === null && perm.permissions.includes('documentation')));
        if(permission.permissions !== undefined) setProgressReportEnabled(permission.permissions.some((perm: any) => perm.booklet === null && perm.permissions.includes('progress_report')));
    }, [permission]);

    useEffect(() => {
        permission.permissions = permissions;
        // updateClientListSelected();
    }, [permissions]);

    const handleDocumentationChange = (checked: boolean) => {
        if (checked) {
            addDocumentationAcces();
            return
        }
        removeDocumentationAcces();
    };

    const handleProgressReportChange = (checked: boolean) => {
        if (checked) {
            addProgressReportAccess();
            return
        }
        removeProgressReportAccess();
    }

    const addProgressReportAccess = () => {
        permissionService.post({
            permissions : ['progress_report'],
            user: `api/users/${client.id}`,
            project: `api/projects/${projectId}`,
            subProject: `api/sub_projects/${subProjectId}`
        }).then((resp: any) => {
            setProgressReportEnabled(true);
            setPermissions([...permissions, resp.data]);
            updatePermissions();
        }).catch((e: any) => {})
    };

    const removeProgressReportAccess = () => {
        const documentation = permission.permissions.find((perm: any) => perm.permissions.includes('progress_report'))

        permissionService.delete(documentation.id).then(() => {
            setProgressReportEnabled(false);
        }).catch((e: any) => {
            console.log(e);
        })
    };

    const addDocumentationAcces = () => {
        permissionService.post({
            permissions : ['documentation'],
            user: `api/users/${client.id}`,
            project: `api/projects/${projectId}`,
            subProject: `api/sub_projects/${subProjectId}`
        }).then((resp: any) => {
            setIsDocumentationEnable(true)
            setPermissions([...permissions, resp.data]);
            updatePermissions();
        }).catch((e: any) => {})
    };

    const removeDocumentationAcces = () => {
        const documentation = permission.permissions.find((perm: any) => perm.permissions.includes('documentation'))

        if(documentation) {
            permissionService.delete(documentation.id).then(() => {
                setIsDocumentationEnable(false);
            }).catch((e: any) => {
                console.log(e);
            })
        }
    };

    const getPermission = (booklet: string) => {
        return permissions.find((p: any) => p.booklet === booklet);
    };

    const patchPermission = (id: string, booklet: string, perms: string[]) => {
        permissionService.put(id, {
            permissions : perms,
            booklet: booklet,
            user: `api/users/${client.id}`,
            project: `api/projects/${projectId}`,
            subProject: `api/sub_projects/${subProjectId}`
        }).then(() => {
            const index  = permissions.findIndex((p: any) => p.id === id);
            const permiss = permissions;
            permiss[index].permissions = perms;
            setPermissions(permiss)
        }).catch((e: any) => {
            displayError();
        })
    };

    const displayError = () => {
        toast.show('error', 'Une erreur est survenue');
        window.location.reload()
    };

    const postPermission = (booklet: any, perms: string[]) => {
        permissionService.post({
            permissions : perms,
            booklet: booklet,
            user: `api/users/${client.id}`,
            project: `api/projects/${projectId}`,
            subProject: `api/sub_projects/${subProjectId}`
        }).then((resp: any) => {
            setPermissions([...permissions, resp.data]);
            updatePermissions();
        }).catch((e: any) => {
            displayError();
        })
    };

    const removePermission = (id: string) => {
        permissionService.delete(id).then(() => {
            const perms = permissions.filter((p: any) => p.id !== id);
            setPermissions([...perms]);
        }).catch((e: any) => {
            displayError();
        })
    };

    return <IonCard>
        <IonCardHeader>
            <IonRow className="ion-align-items-center ion-justify-content-between">
                <IonCol size="7">

                    <IonCardTitle>
                        <IonLabel style={{marginRight: '10px' }}>{client.firstname} {client.lastname}</IonLabel>
                    </IonCardTitle>
                </IonCol>
                <IonCol size="5">

                    {/* <IonCheckbox indeterminate={true} />
            <IonCheckbox indeterminate={true} /> */}
                    <IonItem lines="none">
                        {/* <IonCheckbox
                            slot="end"
                            style={{ marginRight: "56px"}}
                            // indeterminate={isConsultingChecked === 'some'}
                            checked={isConsultingChecked}
                            onIonChange={(e) => handleConsultingAllChange(e.detail.checked)}
                        />
                        <IonCheckbox
                            slot="end"
                            color="primary"
                            style={{ marginRight: "8px"}}
                            // indeterminate={isPointingChecked === 'some'}
                            checked={isPointingChecked}
                            onIonChange={(e) => handlePointingAllChange(e.detail.checked)}
                        /> */}
                    </IonItem>
                </IonCol>
            </IonRow>
        </IonCardHeader>

        <IonCardContent>
            <IonRow style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <div>
                    <div style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}>
                        <div>
                            <IonRow className="ion-align-items-center ion-margin-top">

                                <IonToggle
                                    name="isDocumentationEnable"
                                    checked={isDocumentationEnable}
                                    onIonChange={(e) => handleDocumentationChange(e.detail.checked)}
                                />
                                <IonLabel style={{
                                    fontWeight: "500"
                                }}>Gestion documentaire</IonLabel>
                            </IonRow>
                            <IonRow className="ion-align-items-center ion-margin-top">
                                <IonToggle
                                    name="isProgressReportEnable"
                                    checked={isProgressReportEnabled}
                                    onIonChange={(e) => handleProgressReportChange(e.detail.checked)}
                                />
                                <IonLabel style={{
                                    fontWeight: "500"
                                }}>Accès rapport Power BI</IonLabel>
                            </IonRow>
                        </div>
                    </div>
                </div>

                <div style={{
                    width: '330px'
                }}>
                    {bookletList.map((booklet: any) =>
                        <AccessRow
                            key={booklet}
                            booklet={booklet}
                            getPermission={getPermission}
                            postPermission={postPermission}
                            patchPermission={patchPermission}
                            removePermission={removePermission}
                        />
                    )}
                </div>
            </IonRow>
        </IonCardContent>
    </IonCard>
};

export default AccessCard;
