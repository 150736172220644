export default class ArrayService {
    static sortHierarchical = (array: Array<any>, field: string) => {
        array.sort((a, b) => {
            const aHierarchical = ArrayService.hierarchicalStringToInt(a[field]);
            const bHierarchical = ArrayService.hierarchicalStringToInt(b[field]);
            if (aHierarchical > bHierarchical) {
                return 1;
            }
            if (bHierarchical > aHierarchical) {
                return -1;
            }

            return 0;
        });

        return array;
    };

    static hierarchicalStringToInt = (string: String) => {
        const data = string.split('.').map((i) => (i = i.length < 2 ? '0' + i : i));

        for (let i = data.length; i < 6; i++) {
            data.push('00');
        }
        return parseInt(data.join(''));
    }
}
