import http from '../HttpService'
import axios from "../../axios";

const dataType = `return_of_experiences`
const baseService = http(dataType)
const additionalRequest = {
    post: (data: any) => axios.post(`${dataType}`, data),
    put: (data: any) => axios.put(`${dataType}/${data.id}`, data),
    delete: (id: string) => axios.delete(`${dataType}/${id}`),
}

export default Object.assign(baseService, additionalRequest)
