
import http from '../HttpService'

const dataType = `signatures`
const baseService = http(dataType)
const additionalRequest = {
    delete: () => new Error('not avaivable')
}

export default Object.assign(baseService, additionalRequest)
