import React, {useEffect, useState} from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonRow, IonCol, IonSpinner
} from "@ionic/react";
import {projectService, subProjectService} from "../../../services";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import "./MileStonetaskList.scss";
import RecapList from "../../../components/Task/List/RecapList";
import ArrayService from "../../../services/ArrayService";

export const MileStoneTaskList: React.FC = ({ match }: any) => {
    const [subProject, setSubProject] = useState<any>({});
    const [permissionList, setPermissionList]: any = useState([]);
    const [project, setProject] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);

    const [mileStoneTasks, setMileStoneTasks] = useState<any[]>([]);
    const [documentList, setDocumentList] = useState<({ [key: string]: any })>([])

    const parseTaskList = (taskList: any) => {
        taskList = Object.values(taskList);
        return taskList.map((child: any) => {
            if (child.children) {
                child.children = ArrayService.sortHierarchical(parseTaskList(child.children), "hierarchicalNumber");
            }
            return child;
        });
    };

    useEffect(() => {
        subProjectService.getOne(match.params.subprojectid).then(({data: subProject}) => setSubProject(subProject));
        projectService.getOne(match.params.projectid).then(({data: project}) => setProject(project));

        subProjectService
            .getCurrentPermissionList(match.params.subprojectid)
            .then(({ data: permissionListData }: any) => {
                setPermissionList(permissionListData
                    .filter((permission: any) => permission.permissions.includes('pointage'))
                    .map((permission: any) => permission.booklet)
                )
            })
            .catch(console.log);

        subProjectService.getDocumentList(match.params.subprojectid).then(({ data: documentData }) => {
            setDocumentList(documentData)
        }).catch(e => {})

        subProjectService.getMileStoneTasks(match.params.subprojectid)
            .then((data: any) => {
                setMileStoneTasks(parseTaskList(data.data.tasks))
                setIsLoading(false)
            })
    }, [match.params.subprojectid]);

    return <>
        <IonPage className="ion-page" id="main-content">
            <IonHeader className="center-content">
                <IonToolbar>
                    <NavigationMenu active="projets" backLabel={project.name} backLink={`/projet/${project.id}`} >
                        <h1 id="title-in-navigation-menu">{subProject.name}</h1>
                    </NavigationMenu>
                </IonToolbar>
            </IonHeader>
            <IonContent className="IonPadding">
                <IonRow className={"center-content"}>
                    <IonCol class={"tasks-container"}>
                        {isLoading &&
                            <IonRow className="ion-justify-content-center ion-align-items-center" style={{height: '300px'}}>
                                <IonSpinner />
                            </IonRow>}
                        {!isLoading && mileStoneTasks.length !== 0 && <RecapList taskList={mileStoneTasks} subProjectId={subProject.id} unfoldAll={false} permissionList={permissionList} documentList={documentList}/>}
                        {!isLoading && mileStoneTasks.length === 0 && <IonRow className={"ion-justify-content-center"}><p>Aucune tâche jalon</p></IonRow>}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    </>;
}

export default MileStoneTaskList;
