import { IonPage, IonHeader, IonToolbar, IonContent, IonGrid, IonList, IonItem, IonLabel, IonInput, IonRow, IonCol, IonButton, IonIcon } from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./ChangePassword.scss";
import {
  reloadOutline,
  saveOutline,
} from "ionicons/icons";
import { useForm } from "react-hook-form";
import errorDisplayManager from "../../../errorDisplayManager";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import { authService } from "../../../services";
import { useHistory } from "react-router";
import {useToast} from "../../../contexts/ToastContext";


const ChangePassword: React.FC = () => {
  const Toast = useToast();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
  });
  const history = useHistory()

  const onSubmit = (data: any) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      authService.updatePassword({
        email: JSON.parse(localStorage.getItem('user')!).email,
        password: data.passwordNew
      }).then(() => {
        history.push('/')
      }).catch((e: any) => {
        Toast.show('error', 'Une erreur est survenue')
      })
    }
  };

  useEffect(() => {
    document.title = `Changer mon mot de passe - Canreport`
  }, [])

  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader  className="center-content">
        <IonToolbar>
          <NavigationMenu />
          <TitleOptionSearch title="Changer mon mot de passe" />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">

      <IonGrid style={{
          maxWidth: "550px"
        }}>
          <form method="post" onSubmit={handleSubmit(onSubmit)} noValidate>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">Nouveau mot de passe <span style={{
                    color: "#eb445a",
                  }}
                >{errorDisplayManager('newpassword', errors)}</span>
                </IonLabel>
                <IonInput
                  id="passwordNew"
                  type="password"
                  name="passwordNew"
                  //@ts-ignore
                  ref={register({
                      required: true,
                      minLength: 8,
                      pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,128}$/ },
                    }) as any
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">Confirmation du mot de passe <span style={{
                    color: "#eb445a",
                  }}
                >{errorDisplayManager('passwordconfirm', errors)}</span>
                </IonLabel>
                <IonInput
                  id="passwordConfirm"
                  type="password"
                  name="passwordConfirm"
                  //@ts-ignore
                  ref={register({
                      required: true,
                      validate: value => value === watch('passwordNew')
                    }) as any
                  }
                ></IonInput>
              </IonItem>

              <IonRow
                className="ion-align-items-center"
                style={{
                  margin: "20px 0",
                }}
              >
                <IonCol>
                  <IonRow className="ion-align-items-center">
                    {/* <IonToggle checked={true} />
                    <IonLabel>Rester connecté</IonLabel> */}
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonList>

            <IonRow className="ion-justify-content-between">
              <IonButton fill="clear" routerDirection='none' routerLink="/projets">
                <IonLabel style={{ textTransform: "none" }}>
                  Annuler
                </IonLabel>
              </IonButton>

              <IonButton type="submit" disabled={isSubmitting}>
                <IonIcon
                  slot="start"
                  className={isSubmitting ? `rotate` : ``}
                  icon={isSubmitting ? reloadOutline : saveOutline}
                />
                <IonLabel style={{ textTransform: "none" }}>
                  {isSubmitting ? "Chargement" : "Enregistrer"}
                </IonLabel>
              </IonButton>
            </IonRow>
          </form>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
