import React, {useEffect, useState} from "react";
import {
    IonButton, IonCheckbox, IonIcon, IonItem, IonLabel,
    IonModal,
    IonRow,
} from "@ionic/react";
import './MilestoneSignatureTaskModal.scss';
import {arrowBackOutline} from "ionicons/icons";
import {taskService} from "../../services";

const CheckListTaskModal: React.FC<any> = ({ isOpen = false, onDismiss, updateTaskProgress, task }) => {

    const [items, setItems] = useState({});

    useEffect(() => {
        if(task && isOpen) {
            taskService.getChecklist(task.id).then(d => setItems(d.data));
        }
    }, [task, isOpen]);

    const checkedCount = Object.values(items).filter((i: any) => i.state).length;

    return <IonModal isOpen={isOpen} onDidDismiss={onDismiss} cssClass={"list-rex-modal"}>
        <div>
            <IonRow className={'ion-gap-2 ion-align-items-center'}>
                <IonButton onClick={onDismiss}>
                    <IonIcon icon={arrowBackOutline}/>
                </IonButton>
            </IonRow>

            <div>
                <div className={"form-group"}>
                    <IonLabel color={'dark'}>Liste des tâches {Object.keys(items).length > 0 ? '(' + checkedCount + '/' + Object.values(items).length + ')' : ''}</IonLabel>
                    <div>
                        {Object.entries(items).map(([key, item]: any, i) => <IonRow key={i} className={'ion-align-items-center ion-justify-content-between'}>
                        <IonItem className={'w-full'}>
                            <IonLabel position="fixed">
                                {item.label}
                            </IonLabel>
                            <IonCheckbox
                                slot="end"
                                onClick={() => {
                                    taskService.toggleChecklist(task.id, { state: !item.state, itemId: key}).then(d => {
                                        if(d.data.success) {
                                            updateTaskProgress(d.data.progress);
                                            item.state = !item.state;
                                            setItems({...items});
                                        }
                                    }).catch(() => {
                                        item.state = !item.state;
                                    });
                                }}
                                checked={item.state}
                            />
                        </IonItem>
                        </IonRow>)}
                    </div>
                </div>
            </div>
        </div>
    </IonModal>;
}

export default CheckListTaskModal;
