import React, { useState } from "react";
import { IonLabel, IonChip, IonIcon } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { documentService } from "../../services";

const DocumentItem: React.FC<any> = ({ isEditable = false, document: {
  id,
  originalFilename,
  filePath,
  url
} }: any) => {
  const [isSend, setIsSend] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)

  return isDeleted
    ? <></>
    : <IonChip color="tertiary" onClick={(e) => {
      e.stopPropagation()
      window.open(url, '_blank')}
    }>
      <IonLabel>{originalFilename}</IonLabel>
      {isEditable && <IonIcon icon={closeCircle} onClick={(e) => {
        e.stopPropagation()
        if (!isSend) {
          setIsSend(true)
          documentService.delete(id).then(data => setIsDeleted(true)).catch(e => {})
        }
      }} />}
    </IonChip>
};

export default DocumentItem;
