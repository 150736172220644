import {
  IonContent,
  IonPage,
} from "@ionic/react";
import React from "react";
import "./Login.scss";

import AuthenticationForm from "../../../components/Authentication/AuthenticationForm";
import { logInOutline } from "ionicons/icons";

const Login: React.FC = () => {
  document.title = 'Authentification - Canreport'

  return (
    <IonPage>
      <IonContent>
        <AuthenticationForm
          type='login'
          title="Se connecter"
          titleIcon={logInOutline}
          rememberMe={true}
          primaryButtonLabel="Se connecter"
          primaryButtonIcon={logInOutline}
          secondaryButtonLabel="Mot de passe oublié ?"
          secondaryButtonLink="/mot-de-passe-oublie"
          registration={{
            email: true,
            password: true,
            validatePassword: false,
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
