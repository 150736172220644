import React, { useState } from 'react';
import './EditSubProject.scss';
import { IonPage, IonHeader, IonToolbar, IonContent, useIonViewWillEnter, IonGrid } from '@ionic/react';
import NavigationMenu from '../../../components/Header/NavigationMenu/NavigationMenu';
import TitleOptionSearch from '../../../components/Header/TitleOptionSearch/TitleOptionSearch';
import SubProjectForm from '../../../components/SubProject/SubProjectForm';
import { projectService } from '../../../services';

const EditSubProject: React.FC = () => {
  const [project, setProject]: any = useState({});
  const projectId = window.location.pathname.split('/').reverse()[3]!
  document.title = 'Modifier le projet - Canreport'

  useIonViewWillEnter(() => {
    projectService.getOne(projectId)
      .then(({ data }) => {
        setProject(data)
      })
      .catch(console.log);
  }, [])

  return (
    <IonPage>
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu
            active="projets"
            backLabel={project.name}
            backLink={`/projet/${projectId}`}
          />
          <TitleOptionSearch
            title="Modifier le sous-projet"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <SubProjectForm projectId={projectId} isEditing={true}/>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EditSubProject;
