import React, { useContext, useRef, useState } from 'react';
import { IonGrid, IonRow, IonIcon, IonText, IonList, IonItem, IonLabel, IonInput, IonToggle, IonButton, useIonViewWillEnter } from '@ionic/react';
import {eyeOffOutline, eyeOutline, reloadOutline} from 'ionicons/icons';
import { authService } from '../../services';
import { AuthContext } from '../../App';
import { useForm } from 'react-hook-form';
import errorDisplayManager from '../../errorDisplayManager';
import { useHistory } from 'react-router';

const AuthenticationForm: React.FC<any> = ({
    type,
    title,
    titleIcon,
    rememberMe,
    primaryButtonLabel,
    primaryButtonIcon,
    secondaryButtonLabel,
    secondaryButtonLink,
    registration,
}) => {
    const history = useHistory()
    const { dispatch }: any = useContext(AuthContext)
    const [newPasswordData, setNewPasswordData] = useState<{
      email?: string
      token?: string
    }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isForgotPasswordSend, setIsForgotPassworsSend] = useState(false);
    const [isPasswordReveal, setPasswordReveal] = useState(false);
    const [failAttempt, setFailAttempt] = useState(false);
    const { register, handleSubmit, errors } = useForm({
      mode: "onBlur",
    });

    useIonViewWillEnter(() => {
      const reversePath = window.location.pathname.split('/').reverse()
      const email = reversePath[1]
      const token = reversePath[0]
      setNewPasswordData({
        email,
        token
      })
    })

    const onSubmit = ({ email: username, password }: any) => {
      if (!isSubmitting) {
        setIsSubmitting(true);
        switch (type) {
          case 'login':
            authService.login({
                username,
                password
              }).then(({ tokenResponse, JWTPayload }: any) => {
                dispatch({
                  type: "LOGIN",
                  payload: {
                    token: tokenResponse.data.token,
                    refresh_token: tokenResponse.data.refresh_token,
                    user: {
                      id: JWTPayload.id,
                      email: JWTPayload.username,
                      roles: JWTPayload.roles,
                    },
                  },
                });

                history.push('/projets');
              }).catch((error: any) => {
                setIsSubmitting(false);
                setFailAttempt(true);
                setTimeout(() => setFailAttempt(false), 4000);
              }).finally(() => setIsSubmitting(false));
            break;
          case 'forgotPassword':
            authService.forgotPassword({
              email: username
            }).then(({ tokenResponse, userResponse }: any) => {
              setIsForgotPassworsSend(true)
              // history.push("/");
            }).catch((error: any) => {
              setIsSubmitting(false);
              setFailAttempt(true);
              setTimeout(() => setFailAttempt(false), 4000);
            })
            break;
          case 'newPassword':
            authService.updatePassword({
              ...newPasswordData,
              password,
            }).then((data: any) => {
              authService.login({
                username: newPasswordData.email,
                password
              }).then(({ tokenResponse, JWTPayload }: any) => {
                dispatch({
                  type: "LOGIN",
                  payload: {
                    token: tokenResponse.data.token,
                    refresh_token: tokenResponse.data.refresh_token,
                    user: {
                      id: JWTPayload.id,
                      email: JWTPayload.username,
                      roles: JWTPayload.roles,
                    },
                  },
                });
                history.push("/");
              }).catch((error: any) => {
                setIsSubmitting(false);
                setFailAttempt(true);
                setTimeout(() => setFailAttempt(false), 4000);
              })
            }).catch((error: any) => {
              setIsSubmitting(false);
              setFailAttempt(true);
              setTimeout(() => setFailAttempt(false), 4000);
            })
            break;
        }
      }
    };

  const form = useRef<HTMLFormElement>(null);

  return (
    <IonGrid style={{
        maxWidth: "550px"
      }}>
        <img src="assets/logo_backplan.png" alt=""/>

        <IonRow className="ion-justify-content-center ion-align-items-center p2">
          <IonIcon icon={titleIcon} color="dark" style={{
            width: "32px",
            height: "32px"
          }} />
          <IonText color="dark">
            <h1 style={{
              margin: "0 0 0 10px"
            }}>{title}</h1>
          </IonText>
        </IonRow>

        <form method="post" onSubmit={handleSubmit(onSubmit)} noValidate ref={form}>
          <IonList>
              {registration.email &&
                <IonItem>
                <IonLabel position="stacked">Email <span style={{
                    color: "#eb445a",
                    }}
                >{errorDisplayManager('email', errors)}</span></IonLabel>
                <IonInput
                    name="email"
                    type="email"
                    //@ts-ignore
                    ref={register({
                        required: true,
                        pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
                        },
                    }) as any
                    }
                ></IonInput>
                </IonItem>
              }
            {registration.password && !registration.validatePassword &&
              <IonItem className={'ion-align-items-center ion-margin-top'}>
                <IonLabel position="stacked">Mot de passe <span style={{
                    color: "#eb445a",
                    }}
                >{errorDisplayManager('password', errors)}</span>
                </IonLabel>
                <IonInput
                    name="password"
                    type={isPasswordReveal ? 'text' : 'password'}
                    ref={
                    register({
                        required: true,
                    }) as any
                    }
                ></IonInput>
                <input hidden type="submit" />
                <IonIcon slot="end" className={'ion-cursor-pointer'} icon={isPasswordReveal ? eyeOutline : eyeOffOutline} onClick={() => setPasswordReveal(!isPasswordReveal)} />
              </IonItem>
            }
            {registration.password && registration.validatePassword &&
              <IonItem>
                <IonLabel position="stacked">Mot de passe <span style={{
                    color: "#eb445a",
                  }}
                >{errorDisplayManager('password', errors)}</span>
                </IonLabel>
                <IonInput
                  id="password"
                  type="password"
                  name="password"
                  //@ts-ignore
                  ref={register({
                      required: true,
                      minLength: 8,
                      pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,128}$/ },
                    }) as any
                  }
                ></IonInput>
              </IonItem>
            }

            <IonRow
              className="ion-align-items-center"
              style={{
                margin: "20px 0",
              }}
            >
            {rememberMe && <>
                <IonToggle checked={true} />
                <IonLabel>Rester connecté</IonLabel>
            </>}
            </IonRow>
            <IonRow
                className="ion-justify-content-center"
                style={{
                  margin: "20px 0",
                }}
            >
              <IonLabel style={{ textAlign: "right" }}>
                {failAttempt && !registration.validatePassword && type !== 'forgotPassword' && (
                  <p style={{ color: "#eb445a" }}>
                    Votre email et/ou mot de passe ne correspondent pas
                  </p>
                )}
                {failAttempt && registration.validatePassword && (
                  <p style={{ color: "#eb445a" }}>
                    Une erreur est survenue. Veuillez essayer plus tard.
                  </p>
                )}
                {failAttempt && type === 'forgotPassword' && (
                  <p style={{ color: "#eb445a" }}>
                    L'adresse email ne correspond à aucun compte
                  </p>
                )}
                {/* homah60949@tinkmail.net */}
              </IonLabel>
            </IonRow>
            <IonRow
                className="ion-justify-content-center"
                style={{
                  margin: "20px 0",
                }}
            >
              <IonLabel style={{ textAlign: "right" }}>
                {isForgotPasswordSend && (
                  <p style={{ color: "#3880ff" }}>
                    Un lien de réinitialisation de votre mot de passe vous a été envoyé
                  </p>
                )}
              </IonLabel>
            </IonRow>
          </IonList>

          <IonRow className="ion-justify-content-between">
            <IonButton type="button" fill="clear" routerDirection='none' routerLink={secondaryButtonLink}>
              <IonLabel style={{ textTransform: "none" }}>
                {secondaryButtonLabel}
              </IonLabel>
            </IonButton>
            {!isForgotPasswordSend &&
              <IonButton type="submit" disabled={isSubmitting}>
                <IonIcon
                  slot="start"
                  className={isSubmitting ? `rotate` : ``}
                  icon={isSubmitting ? reloadOutline : primaryButtonIcon}
                />
                <IonLabel style={{ textTransform: "none" }}>
                  {isSubmitting ? "Chargement" : primaryButtonLabel}
                </IonLabel>
              </IonButton>
            }
          </IonRow>
        </form>
      </IonGrid>
  );
};

export default AuthenticationForm;
