import React, {useEffect, useRef, useState} from "react";
import {
    IonButton, IonIcon, IonLabel,
    IonModal,
    IonRow,
    IonText
} from "@ionic/react";
import './MilestoneSignatureTaskModal.scss';
import {arrowBackOutline} from "ionicons/icons";
import Select from "react-select";
import SubProjectService from "../../services/Api/Project/SubProjectService";
import SignatureInput from "../Input/SignatureInput/SignatureInput";
import signatureService from "../../services/Api/SignatureService";
import pointingService from "../../services/Api/PointingService";

const MilestoneSignatureTaskModal: React.FC<any> = ({ isOpen = false, onDismiss, onSave, task = null, subProject }) => {

    const [recipients, setRecipients] = useState<any>([]);

    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        if(Object.keys(subProject).length > 0) {
            SubProjectService.getUsers(subProject.id).then((data: any) => {
                setUsers(data.data.map((d: any) => ({
                    label: d.fullName,
                    value: d.id
                })));
            })
        }
    }, [subProject])

    const signatureInputRef = useRef<any>(null);

    useEffect(() => {
        signatureInputRef.current?.clear();
    }, [isOpen]);

    const saveSignature = () => {
        const baseData = {
            taskUniqueNumber: task.uniqueNumber,
            user: `/api/users/${JSON.parse(localStorage.getItem('user')!).id}`,
            subProject: `/api/sub_projects/${subProject.id}`,
        };

        pointingService.post({...baseData, progress: 100 }).then(() => {
            signatureService.post({...baseData,
                recipients: recipients.map((u: any) => u.value),
                signature: signatureInputRef.current.getBase64(),
            }).then(() => {
                onSave();
            }).catch(err => {
                console.log("Une erreur est survenue", err.message);
            });
        }).catch(() => {
            console.log("Une erreur est survenue");
        });
    };

    return <IonModal isOpen={isOpen} onDidDismiss={onDismiss} cssClass={"list-rex-modal"}>
        <div>
            <IonRow className={'ion-justify-content-between ion-align-items-center'}>
                <IonButton onClick={onDismiss}>
                    <IonIcon icon={arrowBackOutline} />
                </IonButton>
            </IonRow>

            <div>
                <div className={"form-group"}>
                    <IonLabel color={'dark'}>Signature</IonLabel>
                    <SignatureInput ref={signatureInputRef} />
                </div>
                <div className="form-group">
                    <IonLabel color={'dark'}>Notifier la ou les personnes</IonLabel>
                    <Select value={recipients}
                            className={'input'}
                            isMulti={true}
                            onChange={setRecipients}
                            options={users} />
                </div>
                <IonRow className={'ion-justify-content-center ion-gap-3 ion-margin-top'}>
                    <IonButton className="search-icon-button ion-margin-end" onClick={saveSignature}>
                        <IonText className={'text-black'}>Sauvegarder</IonText>
                    </IonButton>
                    <IonButton className="search-icon-button" onClick={() => signatureInputRef.current?.clear()}>
                        <IonLabel className={'text-black'}>Reinitialiser</IonLabel>
                    </IonButton>
                </IonRow>
            </div>
        </div>
    </IonModal>;
}

export default MilestoneSignatureTaskModal;
