
import http from '../../HttpService'
import axios from "../../../axios";

const dataType = `users`
const baseService = http(dataType)
const additionalRequest = {
    isAdmin: () => localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).roles.find((role: string) => role === "ROLE_ADMIN") : false,
    exportList: () => axios.get(dataType + '/export',{ responseType: 'blob' }),
    importUsers: (params: any) => axios.post(dataType + '/import', params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }),
    getUser: () => JSON.parse(localStorage.getItem('user') ?? '{}')
}

export default Object.assign(baseService, additionalRequest)
