import React, {useEffect, useState} from 'react';
import './NavigationMenu.scss';
import {
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  IonPopover,
  IonList,
  IonItem,
} from '@ionic/react';
import { AuthContext } from "../../../App";
import {
  chevronBackOutline,
  clipboardOutline,
  personOutline,
  keyOutline,
  logOutOutline,
  optionsOutline,
  personCircleOutline,
  notificationsOutline
} from 'ionicons/icons';
import {authService, userService} from '../../../services';
import {useToast} from "../../../contexts/ToastContext";
import UserNotificationService from "../../../services/Api/User/UserNotificationService";

const NavigationMenu: React.FC<any> = ({ backLabel, backLink, active, children }) => {
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const { dispatch }: any = React.useContext(AuthContext);
  const Toast = useToast();
  const user = JSON.parse(localStorage.getItem('user')!)
  const [notifCount, setNotifCount] = useState<number>(0);

  useEffect(() => {
    UserNotificationService.getUnreadNotificationsCount().then((data: any) => setNotifCount(data.data.count));
  }, []);

  return (
    <IonButtons>
      <IonGrid style={{ maxWidth: '100%'}}>
        <IonRow className="ion-align-items-center ion-justify-content-between">
          <IonCol size="auto" id="navigation-menu-left">
            {backLabel && backLink &&
              <IonButton routerDirection='none' routerLink={backLink}>
                <IonIcon slot="start" icon={chevronBackOutline} />
                <IonLabel style={{ textTransform: 'none' }}>{backLabel}</IonLabel>
              </IonButton>
            }
          </IonCol>
          <IonCol class={"ion-justify-content-center"} id={"title-in-navigation-menu"} style={{ minWidth: 0}}>
            {children}
          </IonCol>
          <IonCol size="auto" id="navigation-menu-right">
            <IonRow className="ion-align-items-center ion-justify-content-end">
              <IonButton color={active === 'notifications' ? 'primary' : (notifCount > 0 ? 'danger' : '')} style={{ textTransform: 'none' }} routerDirection='none' routerLink="/utilisateur/notifications">
                <IonIcon slot="start" icon={notificationsOutline} />
                <IonLabel>Notifications</IonLabel>
              </IonButton>
              <IonButton color={active === 'projets' ? 'primary' : ''} style={{ textTransform: 'none' }} routerDirection='none' routerLink="/projets">
                <IonIcon slot="start" icon={clipboardOutline} />
                <IonLabel>Projets</IonLabel>
              </IonButton>
              {userService.isAdmin() && <>
                <IonButton color={active === 'utilisateurs' ? 'primary' : ''} style={{ textTransform: 'none' }} routerDirection='none' routerLink="/utilisateurs">
                  <IonIcon slot="start" icon={personOutline} />
                  <IonLabel>Utilisateurs</IonLabel>
                </IonButton>
              </>}

              <IonPopover
                  isOpen={showPopover.open}
                  event={showPopover.event}
                  onDidDismiss={(e) =>
                    setShowPopover({ open: false, event: undefined })
                  }
                  cssClass="popover-main-menu"
                >
                  <IonList>
                    {/* <IonItem routerDirection='none' routerLink="/changer-email">
                      <IonIcon icon={mailOutline} slot="start" />
                      <IonLabel>Changer mon email</IonLabel>
                    </IonItem> */}
                    <IonItem>
                      <IonIcon icon={personCircleOutline} slot="start" />
                      <IonLabel>
                        <IonLabel>{user.email}</IonLabel>
                        {userService.isAdmin() && <IonLabel style={{marginTop: `16px`, marginBottom: `16px`}} color="warning">Administrateur</IonLabel>}
                      </IonLabel>
                    </IonItem>
                    <IonItem routerDirection='none' routerLink="#" onClick={(e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
                      e.preventDefault()
                      setShowPopover({ open: false, event: undefined })
                      authService.forgotPassword({ email: user.email }).then(() =>
                          Toast.show('success', 'Un email vous a été envoyé pour réinitialiser votre mot de passe')
                      ).catch((e) => console.log(e))
                    }}>
                      <IonIcon icon={keyOutline} slot="start" />
                      <IonLabel>Modifier mon mot de passe</IonLabel>
                    </IonItem>
                    <IonItem routerDirection='none' routerLink="#" onClick={() => dispatch({ type: "LOGOUT" })}>
                      <IonIcon color="danger" icon={logOutOutline} slot="start" />
                      <IonLabel color="danger">Se déconnecter</IonLabel>
                    </IonItem>
                  </IonList>
                </IonPopover>
                <IonButton
                  style={{ position: "relative" }}
                  onClick={(e) =>
                    setShowPopover({ open: true, event: e.nativeEvent })
                  }
                >
                  <IonIcon slot="icon-only" icon={optionsOutline} />
                </IonButton>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonButtons>
  );
};

export default NavigationMenu;
