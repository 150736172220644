import React from "react";
import { IonRow, IonCol, IonText, IonIcon } from "@ionic/react";
import { eyeOutline, checkmarkOutline } from "ionicons/icons";

const AccessHeader: React.FC<any> = () => {
  return (
    <IonRow className="ion-justify-content-end" style={{
      background: "#eee",
      margin: "32px 0",
      padding: "8px 20px",
    }}>
      
      {/* TODO: Si admin changer message */}
      <IonCol>
        <IonText style={{
          color: "#555"
        }}>Le client peut</IonText>
      </IonCol>
      <IonCol>
        <IonRow className="ion-align-items-center ion-justify-content-end">
          <IonIcon icon={eyeOutline} style={{
            width: "16px",
            height: "16px",
            color: "#555"
          }} />
          <IonText>
            <span style={{
              margin: "0 24px 0 8px",
              userSelect: "none",
              color: "#555"
            }}>Consulter</span>
          </IonText>

          <IonIcon icon={checkmarkOutline} style={{
            width: "16px",
            height: "16px",
            color: "#555"
          }} />
          <IonText>
            <span style={{
              margin: "0 0 0 8px",
              userSelect: "none",
              color: "#555"
            }}>Pointer</span>
          </IonText>
        </IonRow>
      </IonCol>

    </IonRow>
  );
};

export default AccessHeader;
