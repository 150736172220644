const useDataFlow = (type: string, dataList: any, triggers: any) => {
    const dataFlow = (filterList: any) => {
        if (triggers.length !== filterList.length) {
            throw new Error(`Nombre de filtre invalide. \n actions: ${triggers.length} \n filters: ${filterList.length}`)
        }

        let buffer

        for (let index = 0; index < triggers.length; index++) {
            const trigger = triggers[index];
            const filter = filterList[index];

            buffer = index === 0
                ? trigger(filter, dataList)
                : trigger(filter, buffer)
        }
        return buffer
    }

    const initDataFlow = (filterList: any, initDataList: any) => {
        if (triggers.length !== filterList.length) {
            throw new Error(`Nombre de filtre invalide. \n actions: ${triggers.length} \n filters: ${filterList.length}`)
        }

        let buffer

        for (let index = 0; index < triggers.length; index++) {
            const trigger = triggers[index];
            const filter = filterList[index];

            buffer = index === 0
                ? trigger(filter, initDataList)
                : trigger(filter, buffer)
        }
        return buffer
    }

    return { initDataFlow, dataFlow };
}

export default useDataFlow;
