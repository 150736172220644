import React from "react";
import "../RecapTask.scss";
import TaskCard from "../TaskCard";
import RecapTask from "../RecapTask";

const RecapList: React.FC<any> =  ({ taskList, unfoldAll, breadcrumb = '' }: {
  taskList: any,
  getTaskList: any,
  unfoldAll: boolean,
  breadcrumb: string
}) => {
  return taskList.map((task: any) => task.recapitulative
      ? <RecapTask key={task.id} task={task}
                   unfold={unfoldAll}
                   breadcrumb={breadcrumb} />
      : <TaskCard key={task.id} task={task} breadcrumb={breadcrumb} />
  );
}

export default RecapList;
