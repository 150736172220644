import React, { useState, useCallback } from "react";
import {
  IonGrid,
  IonList,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  useIonViewWillEnter,
  IonTextarea,
  IonItemDivider,
  IonToggle, IonCheckbox,
} from "@ionic/react";
import errorDisplayManager from "../../errorDisplayManager";
import { reloadOutline, addOutline, saveOutline, downloadOutline, closeCircleOutline, checkmarkCircleOutline, readerOutline } from "ionicons/icons";
import { useForm, Controller } from "react-hook-form";
import {  subProjectService } from "../../services";
import "./SubProjectForm.scss";
import { useHistory } from "react-router";
import { useDropzone } from "react-dropzone";
import PeriodSelect from "../Input/Select/PeriodSelect";
import { useToast } from "../../contexts/ToastContext";

const createOption = (label: string) => ({
  label: label + ' jours',
  value: label,
});

const SubProjectForm: React.FC<any> = ({ projectId, isEditing = false }) => {
  const history = useHistory();
  const [subProjectId, setSubProjectId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [failAttempt, setFailAttempt] = useState(false);
  const onError = (error: any) => {
    setIsSubmitting(false);
    setFailAttempt(true);
    setTimeout(() => setFailAttempt(false), 4000);
  };
  const { register, setValue, handleSubmit, errors, control } = useForm({
    mode: "onBlur",
  });

  const [periodeList, setPeriodeList] = useState<any>([]);

  /// ---
  const [extras, setExtras] = useState<any>({});
  const defaultFileLabel = 'Glissez et déplacez votre fichier .xlsx ici ou cliquez pour parcourir vos fichiers'
  const [fileLabel, setFileLabel] = useState<string>(defaultFileLabel);
  const [fileImportIsValid, setFileImportIsValid] = useState<boolean>(false);
  const [clearPlanningRef, setClearPlanningRef] = useState<boolean>(false);
  const [fileImportHasError, setFileImportHasError] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const toast = useToast();

  const loadExtras = (data: any) => {
    const extras: any = {};
    // @ts-ignore
    [...Array(10).keys()].forEach((number) => {
      const nbr = number+1;
      extras[`extra${nbr}Label`] = data[`extra${nbr}Label`] ?? `EXTRA_${nbr}`;
      extras[`extra${nbr}Active`] = data[`extra${nbr}Active`] ?? false;
    });

    setExtras(extras)
  }

   // Allow icon reset
   const onDrop = useCallback(acceptedFiles => {
    setFileImportIsValid(false)
  }, [])
  // Feedback that the file is correct and will be used
  const onDropAccepted = useCallback(acceptedFiles => {
    setFileImportHasError(false)
    setFileLabel(`Le fichier ${acceptedFiles[0].name} va être importé.`);
    setFileImportIsValid(true);
    setFile(acceptedFiles[0])
  }, [])

  // Feedback that the import is incorrect
  const onDropRejected = useCallback(rejectedFiles => {
    const errorCode = rejectedFiles[0].errors[0].code;
    const errorTimeout = 4000;
    setFileImportHasError(true);
    if (errorCode === 'too-many-files') {
      setFileLabel(`Vous ne pouvez importer qu'un seul fichier à la fois`);
    }
    if (errorCode === 'file-invalid-type') {
      setFileLabel(`Uniquement un fichier .xlsx peut être importé`);
    }
    setTimeout(() => {
      setFileLabel(defaultFileLabel);
      setFileImportHasError(false);
    }, errorTimeout)
  }, [])

  const {
    getRootProps,
    getInputProps,
    // rootRef, // Ref to the `<div>`
    // inputRef, // Ref to the `<input>`
    isDragActive
  } = useDropzone({onDrop, onDropAccepted, onDropRejected,
    accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    multiple: false
  })

  /// ---

  useIonViewWillEnter(() => {
    if (isEditing) {
      const id = window.location.pathname.split('/').reverse()[1]!
      setSubProjectId(id)

      subProjectService.getOne(id)
        .then(({ data }) => {
          ['name','description', 'displayHours', 'displayDays', 'reportTaskLabel', 'cancelReportTaskLabel', 'reportIframeUrl'].forEach(el => {
            setValue(el, data[el], {
              shouldValidate: true,
              shouldDirty: true
            })
          });
          loadExtras(data);
          setPeriodeList(data.futureDaysFilter.split(',').filter((e: string) => e.length > 0).map(createOption))
        })
        .catch(console.log);
    } else {
      setPeriodeList([
        {label: "3 jours", value: "3"},
        {label: "7 jours", value: "7"},
        {label: "30 jours", value: "30"}
      ])
    }
  }, [])


  const redirect = () => {
    setIsSubmitting(false);
    ['name','description'].forEach(el => {
      setValue(el, '')
    });

    history.goBack();
  };

  const onSubmit = (formData: any) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const periods = !periodeList ? '' : periodeList.map((period: any) => period.value).join(',');

      if (isEditing) {
        subProjectService.put(subProjectId!, {...extras, ...formData, ...{futureDaysFilter: periods},
        }).then((projectResponse) => {
          if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("clearPlanningRef", new Boolean(clearPlanningRef).toString());
            subProjectService.postImportTask(subProjectId!, formData).then((data) => {
              toast.show('success', 'Le sous-projet a bien été modifié');
              redirect()
            })
            .catch(error => {
              toast.show('error', "L'import du fichier a échoué" + ('response' in error && 'data' in error.response && 'error' in error.response.data ? ': ' + error.response.data.error : ''));
              setIsSubmitting(false)
            })
          } else {
            toast.show('success', 'Le sous-projet a bien été modifié');
            redirect()
          }
        }).catch(e => console.error(e))
      } else {
        subProjectService
          .post({...extras, ...formData, ...{futureDaysFilter: periods, project: `api/projects/${projectId}`}
          })
          .then((projectResponse) => {
            toast.show('success', 'Le sous-projet a bien été ajouté');
            redirect();
          })
          .catch(onError);
      }
    }
  };

  const handleExtraChange = (key: string, value: any) => {
    let pendExtras: any = extras;
    pendExtras[key] = value;
    setExtras(pendExtras)
  }

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <IonGrid className="form-width project-form">
      <form method="post" onSubmit={handleSubmit(onSubmit)} noValidate>
        <IonList
          style={{
            contain: "unset",
          }}
        >

          <IonItem>
            <IonLabel position="stacked">
              Nom{" "}
              <span className="error-color">
                {errorDisplayManager("name", errors)}
              </span>
            </IonLabel>
            <IonInput
              id="name"
              type="text"
              name="name"
              //@ts-ignore
              ref={
                register({
                  required: "true",
                }) as any
              }
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">
              Description
            </IonLabel>
            <IonTextarea
              placeholder="Description du projet"
              rows={6}
              name="description"
              //@ts-ignore
              ref={
                register({}) as any
              }
            ></IonTextarea>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">
              Afficher les heures
            </IonLabel>
            <Controller
                render={({ value, onChange }) => (
                    <IonCheckbox
                        slot="end"
                        onIonChange={(val: any) => onChange(val.target.checked)}
                        checked={value}
                        style={{ margin: 'auto' }}
                    />
                )}
                name="displayHours"
                control={control}
                defaultValue={false}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">
              Afficher les jours de la semaine
            </IonLabel>
            <Controller
                render={({ value, onChange }) => (
                    <IonCheckbox
                        slot="end"
                        onIonChange={(val: any) => onChange(val.target.checked)}
                        checked={value}
                        style={{ margin: 'auto' }}
                    />
                )}
                name="displayDays"
                control={control}
                defaultValue={false}
            />
          </IonItem>

            {isEditing && <>

              {/* <IonItem> */}
              <div {...getRootProps({
                style: {
                  width: '100%',
                  margin: '20px 0',
                  padding: '40px 20px',
                  border: 'dashed 1px silver',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }
              })}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                      <p style={{textAlign: 'center'}}><IonIcon icon={downloadOutline}/> Importer le fichier ...</p> :
                      <p style={{textAlign: 'center', color: (fileImportHasError ? 'red' : 'black')}}><IonIcon icon={
                        fileImportHasError
                            ? closeCircleOutline
                            : fileImportIsValid
                                ? checkmarkCircleOutline
                                : readerOutline
                      }/> {fileLabel}</p>
                }
              </div>
              <div className={'form_row'}>
                <IonCheckbox id={"clearPlanningRef"} aria-labelledby="clear-ref" checked={clearPlanningRef} onIonChange={() => setClearPlanningRef(!clearPlanningRef)}></IonCheckbox>
                <IonLabel id={"clear-ref"}>Ecraser le planning de
                  référence</IonLabel>
              </div>
              {/* </IonItem> */}
              <IonItemDivider>Informations complémentaires</IonItemDivider>
              {
                // @ts-ignore
                [...Array(11).keys()].map((number) => extras[`extra${number}Label`] ? <IonItem key={number}>
                  {/* <IonLabel>Checked: {JSON.stringify(checked)}</IonLabel> */}
                  {/* <IonToggle checked={false} onIonChange={e => setChecked(e.detail.checked)} /> */}
                  <IonInput onIonChange={(e) => handleExtraChange(`extra${number}Label`, e.detail.value)}
                            value={extras[`extra${number}Label`]}/>
                  <IonToggle onIonChange={(e) => handleExtraChange(`extra${number}Active`, e.detail.checked)}
                             checked={extras[`extra${number}Active`]}/>
                </IonItem> : <div key={number}></div>)
              }
            </>}
          <IonItemDivider>Périodes personnalisées</IonItemDivider>

          <PeriodSelect periodList={periodeList}
                        setPeriodList={setPeriodeList}
                        createOption={createOption}/>

          <IonItem>
            <IonLabel position="stacked">
              Label bouton action
            </IonLabel>
            <IonTextarea
                placeholder="Label bouton action"
                rows={6}
                name="reportTaskLabel"
                ref={
                  register({}) as any
                }
            ></IonTextarea>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">
              Label bouton action annulation
            </IonLabel>
            <IonTextarea
                placeholder="Label bouton action annulation"
                rows={6}
                name="cancelReportTaskLabel"
                ref={
                  register({}) as any
                }
            ></IonTextarea>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">
              Lien d'intégration du rapport PowerBI
            </IonLabel>
            <IonTextarea
                placeholder="Lien"
                rows={6}
                name="reportIframeUrl"
                //@ts-ignore
                ref={
                  register({}) as any
                }
            ></IonTextarea>
          </IonItem>

          <IonRow
            className="ion-align-items-center"
            style={{
              margin: "20px 0",
            }}
          >
            <IonCol>
              <IonRow className="ion-align-items-center">
                {failAttempt && (
                  <p className="error-color">
                    Une erreur est survenue. Veuillez ressayer plus tard.
                  </p>
                )}
              </IonRow>
            </IonCol>
          </IonRow>
        </IonList>

        <IonRow className="ion-justify-content-between">
          <IonButton
            fill="clear"
            onClick={redirect}
          >
            <IonLabel style={{ textTransform: "none" }}>Annuler</IonLabel>
          </IonButton>

          <IonButton type="submit" disabled={isSubmitting}>
            <IonIcon
              slot="start"
              className={isSubmitting ? `rotate` : ``}
              icon={isSubmitting ? reloadOutline : isEditing ? saveOutline : addOutline}
            />
            <IonLabel style={{ textTransform: "none" }}>
              {isSubmitting ? "Chargement" : isEditing ? "Enregistrer" : "Créer"}
            </IonLabel>
          </IonButton>
        </IonRow>
      </form>
    </IonGrid>
  );
};

export default SubProjectForm;
