import http from '../HttpService'
import axios from "../../axios";

const dataType = `tasks`
const baseService = http(dataType)
const additionalRequest = {
    getRexes: (id: string) => axios.get(`${dataType}/${id}/rex`),
    getChecklist: (id: string) => axios.get(`${dataType}/${id}/checklist`),
    toggleChecklist: (id: string, content: any) => axios.post(`${dataType}/${id}/toggle-checklist`, content),
    post: () => new Error('not avaivable'),
    put: () => new Error('not avaivable'),
    delete: () => new Error('not avaivable'),
    reset: (id: string) => axios.post(`${dataType}/${id}/reset`, {}),
    cancel: (id: string) => axios.post(`${dataType}/${id}/cancel`, {}),
    report: (id: string) => axios.post(`${dataType}/${id}/report`, {}),
}

export default Object.assign(baseService, additionalRequest)
