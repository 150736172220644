import React from "react";
import { IonIcon } from '@ionic/react'
import {information} from "ionicons/icons";

//TODO: Améliorer avec un truc plus graphique
const EmptyList: React.FC<any> = ({ list, isDisplayReady = true, isSearching = false }) => {
  const isListEmpty = list.length === 0
  const displayEmptyListMessage = isListEmpty && isDisplayReady && !isSearching
  const displayUserSearchingMessage = isListEmpty && isDisplayReady && isSearching

  return <>
    {displayEmptyListMessage && <div style={styles.flexCenter}><IonIcon style={styles.icon} icon={information} /> Il n'y a pas de dossier pour le moment, peut-être que l'import des tâches n'a pas été effectué ou qu'il n'y a pas de dossier associé aux tâches</div>}
    {displayUserSearchingMessage && <p>Aucun résultat</p>}
  </>
};

export const styles = {
  flexCenter: {
    margin: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  icon: {
    height: '20px',
    width: '20px',
    color: '#ad173d'
  }
}

export default EmptyList;
