import {
    createContext,
} from 'react';
import './Toast.scss';

type SubprojectContextInstance = {
    subProject: any;
    permissionList: any;
    showSignatureModal: any;
    showRexModal: any;
    showModal: any;
    documentList: any;
};

export const SubprojectDetailContext = createContext<SubprojectContextInstance>({} as SubprojectContextInstance);
export const { Provider } = SubprojectDetailContext;
