import React from 'react';
import './EditProject.scss';
import ProjectForm from '../../../components/Project/ProjectForm';
import { IonPage, IonHeader, IonToolbar, IonContent, IonGrid } from '@ionic/react';
import NavigationMenu from '../../../components/Header/NavigationMenu/NavigationMenu';
import TitleOptionSearch from '../../../components/Header/TitleOptionSearch/TitleOptionSearch';

const EditProject: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu
            active="projets"
            backLabel="Projets"
            backLink="/projets"
          />
          <TitleOptionSearch
            title="Modifier le projet"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="center-content">
          <ProjectForm isEditing={true}/>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EditProject;
