import { IonContent, IonHeader, IonPage, IonToolbar, IonGrid, } from "@ionic/react";
import React from "react";
import "./EditUser.scss";
import UserForm from "../../../components/User/UserForm";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";

const EditUser: React.FC = () => {
  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu
            active="utilisateurs"
            backLabel="Utilisateurs"
            backLink="/utilisateurs"
          />
          <TitleOptionSearch
            title="Modifier l'utilisateur"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <UserForm isEditing={true} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EditUser;
