import React, { useState } from "react";
import {
  IonGrid,
  IonList,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonToggle,
  IonButton,
  IonIcon,
  useIonViewWillEnter,
} from "@ionic/react";
import errorDisplayManager from "../../errorDisplayManager";
import { reloadOutline, addOutline, saveOutline } from "ionicons/icons";
import { useForm } from "react-hook-form";
import { userService } from "../../services";
import "./UserForm.scss";
import { useHistory } from "react-router";
import { useToast } from "../../contexts/ToastContext";
// import "react-telephone-input/css/default.css";

const UserForm: React.FC<any> = ({ isEditing = false }) => {
  const history = useHistory();
  const toast = useToast();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [failAttempt, setFailAttempt] = useState(false);
  const onError = (error: any) => {
    setIsSubmitting(false);
    setFailAttempt(true);
    setTimeout(() => setFailAttempt(false), 4000);
  };
  const { register, setValue, handleSubmit, errors } = useForm({
    mode: "onBlur",
  });

  useIonViewWillEnter(() => {
    if (isEditing) {
      const id = window.location.pathname.split('/').slice(-2, -1).pop()
      setUserId(id)

      userService.getOne(id!)
        .then(({ data }) => {
          document.title = `${data.firstname} ${data.lastname} (modifier) - Canreport`;

          ['firstname','lastname','email','position','phone'].forEach(el => {
            setValue(el, data[el], {
              shouldValidate: true,
              shouldDirty: true
            })
          });
          setIsAdmin(!!data.roles.find((role: string) => role === "ROLE_ADMIN"))
        })
        .catch(console.log);
    }
  }, [])


  const redirect = () => {
    setIsSubmitting(false);
    setIsAdmin(false);
    ['firstname','lastname','email','position','phone'].forEach(el => {
      setValue(el, '')
    });
    if (userId) {
      history.push(`/utilisateur/${userId}`);
    } else {
      history.push("/utilisateurs");
    }
  };

  const onSubmit = ({ firstname, lastname, email, position, phone }: any) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      // const phoneFormated = phone ? phone.replace(/\s/g, "") : "";

      if (isEditing) {
        userService.put(userId!, {
          firstname,
          lastname,
          email,
          position,
          roles: isAdmin ? ["ROLE_USER", "ROLE_ADMIN"] : ["ROLE_USER"],
          // phone: phoneFormated,
          phone,
        }).then((userResponse) => {
          toast.show('success', "L'utilisateur a bien été modifié");
          redirect()
        }).catch(e => {})
      } else {
        userService
          .post({
            firstname,
            lastname,
            email,
            position,
            roles: isAdmin ? ["ROLE_USER", "ROLE_ADMIN"] : ["ROLE_USER"],
            // phone: phoneFormated,
            phone,
          })
          .then((userResponse) => {
              toast.show('success', "L'utilisateur a bien été ajouté");
              redirect();
          })
          .catch(onError);
      }
    }
  };

  return (
    <IonGrid className="form-width user-form">
      <form method="post" onSubmit={handleSubmit(onSubmit)} noValidate>
        <IonList
          style={{
            contain: "unset",
          }}
        >
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">
                  Prénom{" "}
                  <span className="error-color">
                    {errorDisplayManager("firstname", errors)}
                  </span>
                </IonLabel>
                <IonInput
                  id="firstname"
                  type="text"
                  name="firstname"
                  //@ts-ignore
                  ref={
                    register({
                      required: "true",
                    }) as any
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">
                  Nom{" "}
                  <span className="error-color">
                    {errorDisplayManager("lastname", errors)}
                  </span>
                </IonLabel>
                <IonInput
                  id="lastname"
                  type="text"
                  name="lastname"
                  //@ts-ignore
                  ref={
                    register({
                      required: "true",
                    }) as any
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonItem>
            <IonLabel position="stacked">
              Email{" "}
              <span className="error-color">
                {errorDisplayManager("email", errors)}
              </span>
            </IonLabel>
            <IonInput
              id="email"
              type="email"
              name="email"
              ref={
                //@ts-ignore
                register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
                  },
                }) as any
              }
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Intitulé du poste</IonLabel>
            <IonInput
              id="position"
              type="text"
              name="position"
              //@ts-ignore
              ref={register({}) as any}
            ></IonInput>
          </IonItem>

          <IonRow>
            <IonItem
              style={{
                overflow: "unset",
                zIndex: "10",
              }}
            >
              <IonLabel position="stacked">
                Téléphone{" "}
                <span className="error-color">
                  {errorDisplayManager("phone", errors)}
                </span>
              </IonLabel>

              {/* <Controller
                  as={ReactTelephoneInput}
                  id="phone"
                  type="tel"
                  name="phone"
                  defaultCountry="fr"
                  flagsImagePath="assets/flags.png"
                  control={control}
                  style={{ width: "100%" }}
                /> */}
              <IonInput
                type="tel"
                id="phone"
                name="phone"
                maxlength={10}
                pattern="tel"
                //@ts-ignore
                ref={
                  register({
                    minLength: 10,
                  }) as any
                }
                // onKeyDown={e => {
                //   if (e.key === 'Backspace') {
                //     if (
                //       tel?.length === 4 ||
                //       tel?.length === 5 ||
                //       tel?.length === 7 ||
                //       tel?.length === 8 ||
                //       tel?.length === 10 ||
                //       tel?.length === 11 ||
                //       tel?.length === 13) {
                //         //@ts-ignore
                //       setTel(e.target.value.slice(0, -2))
                //       console.log('need del backspace')
                //       console.log('tel', tel)
                //     }
                //   }
                // }}
                // onIonChange={(e) => {
                //   console.log(e.detail.value);
                //   if (
                //     e.detail.value?.length === 2 ||
                //     e.detail.value?.length === 5 ||
                //     e.detail.value?.length === 8 ||
                //     e.detail.value?.length === 11
                //   ) {
                //     setTel(e.detail.value + " ");
                //   } else {
                //     setTel(e.detail.value!);
                //   }
                // }}
              ></IonInput>
            </IonItem>
          </IonRow>
          {/*
            <ReactTelephoneInput
              id="phone"
              type="tel"
              name="phone"
              //@ts-ignore
              ref={register({
                }) as any
              }
              defaultCountry="fr"
              flagsImagePath="assets/flags.png"
              // onChange={handleInputChange}
              // onBlur={handleInputBlur}
              style={{ width: "100%" }}
            /> */}

          <IonRow className="ion-align-items-center ion-margin-top">
            <IonToggle
              name="isAdmin"
              checked={isAdmin}
              onIonChange={(e) => setIsAdmin(e.detail.checked)}
              color="warning"
            />
            <IonLabel
              color="warning"
              style={{
                fontWeight: "500",
              }}
            >
              Administrateur
            </IonLabel>
          </IonRow>
          <IonRow
            className="ion-align-items-center"
            style={{
              margin: "20px 0",
            }}
          >
            <IonCol>
              <IonRow className="ion-align-items-center">
                {failAttempt && (
                  <p className="error-color">
                    Une erreur est survenue. Veuillez ressayer plus tard.
                  </p>
                )}
              </IonRow>
            </IonCol>
          </IonRow>
        </IonList>

        <IonRow className="ion-justify-content-between">
          <IonButton
            fill="clear"
            onClick={redirect}
          >
            <IonLabel style={{ textTransform: "none" }}>Annuler</IonLabel>
          </IonButton>

          <IonButton type="submit" disabled={isSubmitting}>
            <IonIcon
              slot="start"
              className={isSubmitting ? `rotate` : ``}
              icon={isSubmitting ? reloadOutline : isEditing ? saveOutline : addOutline}
            />
            <IonLabel style={{ textTransform: "none" }}>
              {isSubmitting ? "Chargement" : isEditing ? "Enregistrer" : "Créer"}
            </IonLabel>
          </IonButton>
        </IonRow>
      </form>
    </IonGrid>
  );
};

export default UserForm;
