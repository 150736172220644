import CreatableSelect from 'react-select/creatable';
import React, {useState} from "react";

const PeriodSelect: React.FC<any> = ({ periodList, setPeriodList, createOption }) => {
    const [periodeText, setPeriodeText] = useState('');

    const components = {
        DropdownIndicator: null,
    };

    const handleChange = (value: any, actionMeta: any) => {
        setPeriodList(value)
    };

    const handleInputChange = (inputValue: string) => {
        setPeriodeText(inputValue)
    };

    const handleKeyDown = (event: any) => {
        if (!periodeText) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setPeriodeText('')
                const periods = !periodList ? [] : periodList;
                if (/^[0-9\b]+$/.test(periodeText) && !periods.some((period: any) => period.value === periodeText)) {
                    setPeriodList([...periods, createOption(periodeText)]);
                    event.preventDefault();
                }
            break;
        }
    };

    return (
        <CreatableSelect
            components={components}
            inputValue={periodeText}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Taper les périodes en jours puis Entrer ..."
            value={periodList}
            />
    );
}

export default PeriodSelect;
