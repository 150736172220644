
import http from '../../HttpService'
import axios from "../../../axios";

const dataType = `user_notifications`
const baseService = http(dataType)
const additionalRequest = {
    getNotifications: () => axios.get(`${dataType}`),
    getUnreadNotificationsCount: () => axios.get(`${dataType}/unread`),
}

export default Object.assign(baseService, additionalRequest)
