
import "./NewPassword.scss";
import AuthenticationForm from "../../../components/Authentication/AuthenticationForm";
import { IonPage, IonContent } from "@ionic/react";
import React from "react";
import { keyOutline, logInOutline } from "ionicons/icons";

// TODO: Ajouter la mecaniqe de la création du mot de passe
// S'assurer du fonctionnement est de la non-regrassion du login
const NewPassword: React.FC = () => {
  document.title = 'Authentification - Canreport'

  return (
    <IonPage>
      <IonContent>
        <AuthenticationForm
            type='newPassword'
            title="Nouveau mot de passe"
            titleIcon={keyOutline}
            rememberMe={true}
            primaryButtonLabel="Se connecter"
            primaryButtonIcon={logInOutline}
            registration={{
              email: false,
              password: true,
              validatePassword: true,
            }}
          />
      </IonContent>
    </IonPage>
  );
};

export default NewPassword;
