import React, {useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback} from "react";
import './SignatureInput.scss';

const SignatureInput: any = forwardRef(({ onChange }: any, ref) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [ctx, setCtx] = useState<CanvasRenderingContext2D|null>(null);

    useImperativeHandle(ref, () => ({
        clear: () => {
            if(ctx && canvasRef.current) {
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                setDrawing(false);
            }
        },
        getBase64: () => {
            if(!canvasRef.current) return null;
            return canvasRef.current.toDataURL();
        }
    }));

    useEffect(() => {
        const canvas = canvasRef.current;

        if(canvas) {
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.lineWidth = 3;
                ctx.lineJoin = ctx.lineCap = 'round';
                setCtx(ctx);
            }
        }
    }, [canvasRef]);

    const [isDrawing, setDrawing] = useState<boolean>(false);

    const onMouseMove = (e: any) => {
        if(ctx && isDrawing) {
            const [positionX, positionY] = getCursorPosition(e);
            ctx.lineTo(positionX, positionY);
            ctx.stroke();
        }
    };

    const getCursorPosition = (event: any) => {
        const positionX = event.clientX - event.target.getBoundingClientRect().x;
        const positionY = event.clientY - event.target.getBoundingClientRect().y;
        return [positionX, positionY];}

    const onMouseDown = (e: any) => {
        setDrawing(true);
        if(ctx) {
            ctx.beginPath();
            const [positionX, positionY] = getCursorPosition(e);
            ctx.moveTo(positionX, positionY);
        }
    };

    const onMouseUp = (e: any) => {
        setDrawing(false);
    };

    return (
        <canvas onMouseDown={onMouseDown} onMouseMove={onMouseMove} ref={canvasRef} onMouseUp={onMouseUp} width={200} height={200} />
    );
});

export default SignatureInput;
