import React, {useEffect, useState} from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
} from "@ionic/react";
import {projectService, subProjectService} from "../../../services";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import "./ReportPowerBI.scss";

export const ReportPowerBI: React.FC = ({ match }: any) => {
    const [subProject, setSubProject] = useState<any>({});
    const [project, setProject] = useState<any>({});

    useEffect(() => {
        subProjectService.getOne(match.params.subprojectid).then(({data: subProject}) => setSubProject(subProject));
        projectService.getOne(match.params.projectid).then(({data: project}) => setProject(project));
    }, [match.params.subprojectid])

    return (
        <IonPage className="ion-page" id="main-content">
            <IonHeader className="center-content">
                <IonToolbar>
                    <NavigationMenu active="projets" backLabel={project.name} backLink={`/projet/${project.id}`} >
                        <h1 id="title-in-navigation-menu">{subProject.name}</h1>
                    </NavigationMenu>
                </IonToolbar>
            </IonHeader>
            <IonContent className="IonPadding">
                <iframe width={'100%'} height={'100%'} src={subProject.reportIframeUrl} />
            </IonContent>
        </IonPage>
    );
}
