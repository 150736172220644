import React, { useState } from "react";
import "./Expandable.scss";
import {chevronDown, chevronForward} from "ionicons/icons";
import {
    IonRow,
    IonButton,
    IonIcon,
    IonCol
} from "@ionic/react";

const Expandable: React.FC<any> = ({ children }) => {
  const [isExpanded, setExpanded] = useState(true);

  return (
      <div className={"expandable_container"}>
          <IonRow className={"ion-justify-content-between"} onClick={e => setExpanded(!isExpanded)}>
              <IonCol className={"m-auto"}>
                  Filtres
              </IonCol>
              <IonCol size={"auto"}>
                  <IonButton fill={isExpanded ? "solid" : "clear"}>
                      <IonIcon icon={isExpanded ? chevronDown : chevronForward}></IonIcon>
                  </IonButton>
              </IonCol>
          </IonRow>
          <div className={isExpanded ? '' : 'hidden'}>
              { children }
          </div>
      </div>
  );
};

export default Expandable;
