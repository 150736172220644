import {IonButton, IonIcon, IonModal} from "@ionic/react";
import React, {useCallback, useState} from "react";
import {checkmarkCircleOutline, closeCircleOutline, downloadOutline, readerOutline} from "ionicons/icons";
import {useDropzone} from "react-dropzone";
import {useToast} from "../../../contexts/ToastContext";
import {subProjectService} from "../../../services";

const PermissionsImportModal: React.FC<any> = ({ subProjectId, isOpen, closeModal }: any) => {

    const defaultFileLabel = 'Glissez et déplacez votre fichier .xlsx ici ou cliquez pour parcourir vos fichiers'
    const [fileLabel, setFileLabel] = useState<string>(defaultFileLabel);
    const [fileImportIsValid, setFileImportIsValid] = useState<boolean>(false);

    const [fileImportHasError, setFileImportHasError] = useState<boolean>(false);
    const [file, setFile] = useState<any>();

    const onDrop = useCallback(acceptedFiles => {
        setFileImportIsValid(false)
    }, [])
    const onDropAccepted = useCallback(acceptedFiles => {
        setFileImportHasError(false)
        setFileLabel(`Le fichier ${acceptedFiles[0].name} va être importé.`);
        setFileImportIsValid(true);
        setFile(acceptedFiles[0])
    }, [])
    const onDropRejected = useCallback(rejectedFiles => {
        const errorCode = rejectedFiles[0].errors[0].code;
        const errorTimeout = 4000;
        setFileImportHasError(true);
        if (errorCode === 'too-many-files') {
            setFileLabel(`Vous ne pouvez importer qu'un seul fichier à la fois`);
        }
        if (errorCode === 'file-invalid-type') {
            setFileLabel(`Uniquement un fichier .xlsx peut être importé`);
        }
        setTimeout(() => {
            setFileLabel(defaultFileLabel);
            setFileImportHasError(false);
        }, errorTimeout)
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({onDrop, onDropAccepted, onDropRejected,
        accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        multiple: false
    })

    const toast = useToast();

    const importPermissions = () => {
        if(file) {
            const formData = new FormData();

            formData.append("file", file);

            subProjectService.importPermissions(subProjectId, formData).then((data) => {
                toast.show('success', 'Les utilisateurs ont bien été importés');
                closeModal(true);
            })
            .catch(error => {
                toast.show('error', "L'import du fichier a échoué" + ('response' in error && 'data' in error.response && 'error' in error.response.data ? ': ' + error.response.data.error : ''));
            })
        }
    }

  return (
      <IonModal isOpen={isOpen} onWillDismiss={closeModal}>
          <p>Import en masse</p>
          <div {...getRootProps({
              style: {
                  width: '100%',
                  margin: '20px 0',
                  padding: '40px 20px',
                  border: 'dashed 1px silver',
                  borderRadius: '8px',
                  cursor: 'pointer',
              }
          })}>
              <input {...getInputProps()} />
              {
                  isDragActive ?
                      <p style={{textAlign: 'center'}}><IonIcon icon={downloadOutline}/> Importer le fichier ...</p> :
                      <p style={{textAlign: 'center', color: (fileImportHasError ? 'red' : 'black')}}><IonIcon icon={
                          fileImportHasError
                              ? closeCircleOutline
                              : fileImportIsValid
                                  ? checkmarkCircleOutline
                                  : readerOutline
                      }/> {fileLabel}</p>
              }
          </div>
          <IonButton onClick={importPermissions}>Importer</IonButton>
      </IonModal>
  );
};

export default PermissionsImportModal;
