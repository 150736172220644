import React from "react";
import ProjectMenu from "../Menu/ProjectMenu";
import { IonCard, IonCardHeader, IonRow, IonCardTitle, IonLabel, IonCardContent, IonItem } from "@ionic/react";
import { IProject } from "../../Interface/Project";
import "./ProjectCard.scss"
import { userService } from "../../services";

const ProjectCard: React.FC<any> = ({ project, getProjectList }: {
  project: IProject,
  getProjectList: any
}) => {
  return (
    <IonCard className="project-card">
      <IonCardHeader>
        <IonRow className="ion-align-items-center ion-justify-content-between">
          {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}

          <IonCardTitle>
            <IonItem
              className="project-card__title"
              routerLink={`/projet/${project.id}`}
              routerDirection="none"
              lines="none"
            >
              <IonLabel className="project-card__name">
                {project.name}
              </IonLabel>
            </IonItem>
          </IonCardTitle>

          {userService.isAdmin() &&
            <ProjectMenu projectId={project.id} isArchived={project.archived} getProjectList={getProjectList} />
          }
        </IonRow>
      </IonCardHeader>

      {project.description &&
        <IonCardContent>
          {project.description}
        </IonCardContent>
      }
    </IonCard>
  );
};

export default ProjectCard;
