
export default (type: string, errors: any) => {
    switch (type) {
        case 'name':
            return errors.name && errors.name.type === "required"
                ? "est requis"
                : "";
        case 'email':
            return errors.email && errors.email.type === "required"
                ? "est requis"
                : errors.email && errors.email.type === "pattern"
                    ? "n'est pas un email valide (ex: nom@mail.com)"
                    : "";
        case 'password':
            return errors.password && errors.password.type === "required"
                ? "est requis"
                : errors.password && errors.password.type === "minLength"
                    ? "doit comporter un minimum de 8 caractères"
                    : errors.password && errors.password.type === "pattern"
                        ? "doit comporter au moins une lettre majuscule, minuscule et un chiffre"
                        : "";
        case 'firstname':
            return errors.firstname && errors.firstname.type === "required"
                ? "est requis"
                : "";
        case 'lastname':
            return errors.lastname && errors.lastname.type === "required"
                ? "est requis"
                : "";
        case 'phone':
            return errors.phone && errors.phone.type === "minLength"
                ? "n'as pas un format correct (ex: 06 12 34 56 78)"
                : "";
        case 'oldpassword':
            return errors.passwordOld && errors.passwordOld.type === "required"
                ? "est requis"
                : "";
        case 'newpassword':
            return errors.passwordNew && errors.passwordNew.type === "required"
                ? "est requis"
                : errors.passwordNew && errors.passwordNew.type === "minLength"
                ? "doit comporter un minimum de 8 caractères"
                : errors.passwordNew && errors.passwordNew.type === "pattern"
                    ? "doit comporter au moins une lettre majuscule, minuscule et un chiffre"
                : "";
        case 'passwordconfirm':
            return errors.passwordConfirm && errors.passwordConfirm.type === "required"
                ? "est requis"
                : errors.passwordConfirm && errors.passwordConfirm.type === "validate"
                ? "ne correspondent pas au nouveau mot de passe"
                : "";
        default:
            throw new Error('invalid type')
    }
}
