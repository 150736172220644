const useSearch = (type: string, setOnChange?: any) => {
    const triggerSearch = (searchText: string, list: any) => {
        if (searchText === '') {
            if (setOnChange) setOnChange(list)
            return list
        }

        localStorage.setItem("search", searchText);
        const updatedList = filterSelector(searchText.toLowerCase(), list);
        if (setOnChange) setOnChange(updatedList)
        setOnChange(updatedList)
        return updatedList;
    };

    const filterSelector = (searchText: string, list: any) => {
        switch (type) {
            case "user":
                return search(searchText, list, userKeys)
            case "project":
                return search(searchText, list, projectKeys)
            case "subproject":
                return search(searchText, list, subProjectKeys)
            // case "task":
                // return taskSearch(searchText, list)
        }
    };

    const search = (searchText: string, list: any, keyList: any) => {
        return list.filter((el: any) => {
            return !!keyList.find((key: any) => {
                if (!el[key]) return false;
                return el[key].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').search(searchText.toLowerCase()) !== -1;
            })
        })
    };

    return { triggerSearch };
}

const userKeys = [
    "email",
    "phone",
    "position",
    "firstname",
    "lastname",
    "companyName",
]

const projectKeys = [
    "name",
    "description",
]

const subProjectKeys = [
    "name"
]

export default useSearch;
