import {
    IonContent,
    IonHeader,
    IonPage,
    useIonViewWillEnter,
    IonGrid,
    IonToolbar,
    IonLabel,
} from "@ionic/react";
import React, {useState} from "react";
import "./NotificationList.scss";
import UserNotificationService from "../../../services/Api/User/UserNotificationService";
import NotificationCard from "../../../components/Notification/NotificationCard";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import {taskService} from "../../../services";
import {ITask} from "../../../Interface/Task";
import DetailledTaskModal from "../../../components/Task/DetailledTaskModal";

const List = ({ list, openTaskDetail }: any) => <>
  {list.map((notif: any) => <NotificationCard key={notif.id} notif={notif} openTaskDetail={openTaskDetail} />)}
</>

const NotificationList: React.FC = () => {

  const [ notifications, setNotifications ] = useState<any[]>([]);
  const [ notif, setNotif ] = useState<any[]>([]);

  useIonViewWillEnter(() => {
    document.title = `Notifications - Canreport`
    UserNotificationService.getNotifications().then((data: any) => setNotifications(data.data))
  }, [])

    const [taskData, setTaskData] = useState<ITask>();

    const openTaskDetail = (notif: any) => {
        setNotif(notif);
        taskService.getOne(notif.contentId).then(({ data: taskData }) => {
            setTaskData(taskData);
        }).catch(e => {})
    };

  return (
      <>
          <IonContent className="ion-padding">
              <DetailledTaskModal task={taskData} taskData={notif} onDismiss={() => setTaskData(undefined)} />
          </IonContent>
          <IonPage className="ion-page" id="main-content">
              <IonHeader className="center-content">
                  <IonToolbar>
                      <NavigationMenu active="notifications" />
                      <TitleOptionSearch
                          title="Notifications"
                          listCount={notifications.length || "0"}
                      />
                  </IonToolbar>
              </IonHeader>
              <IonContent className="IonPadding">
                  <IonGrid className="center-content">
                      <List list={notifications} openTaskDetail={openTaskDetail} />
                      {/* <EmptyList list={userListDisplayed} isSearching={isSearching} /> */}
                      {notifications.length === 0 && <div style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          alignItems: 'center',
                          marginTop: '150px',
                      }}>
                          <IonLabel style={{marginBottom: '20px'}}>
                              Aucune notification
                          </IonLabel>
                      </div>}
                  </IonGrid>
              </IonContent>
          </IonPage>
      </>
  );
};

export default NotificationList;
