
import http from '../../HttpService'
import axios from '../../../axios'

// ? API etrange verifié l'utilité ?

const dataType = `user_token`
const baseService = http(dataType)
const additionalRequest = {
    post: () => new Error('not avaivable'),
    put: () => new Error('not avaivable'),
    delete: () => new Error('not avaivable'),
    update: (params: any) => axios.post(`${dataType}/check`, params)

}

export default Object.assign(baseService, additionalRequest)
