import './NotificationCard.scss';
import {
    IonCard,
    IonButton,
    IonRow,
    IonCardTitle,
    IonCardSubtitle,
    IonLabel,
    IonCardContent,
    IonCol, IonIcon,
} from '@ionic/react';
import React from 'react';
import {arrowForwardOutline} from "ionicons/icons";

const NotificationCard: React.FC<any> =  ({ notif, openTaskDetail }: any) => {
  return (
      <IonCard className="user-card">
        <IonCardContent>
            <IonRow className="ion-align-items-center ion-justify-content-between">
                <IonCol size={"10"}>
                    <IonCardTitle>
                        <IonLabel className="user-card__name" color={notif.readAt ? "black" : "primary"}>
                            {notif.message}
                        </IonLabel>
                    </IonCardTitle>
                    <IonCardSubtitle>
                        {notif.createdAt}
                    </IonCardSubtitle>
                </IonCol>
                <IonCol size={"2"}>
                    <IonRow className={"ion-justify-content-center"}>
                        {notif.target === 'SUB_PROJECT' ?
                            <IonButton icon-only routerLink={`/projet/${notif.content.project}/sous-projet/${notif.contentId}`}>
                                <IonIcon icon={arrowForwardOutline} />
                            </IonButton>
                            : <IonButton icon-only onClick={() => openTaskDetail(notif)}>
                                <IonIcon icon={arrowForwardOutline} />
                            </IonButton>
                        }
                    </IonRow>
                </IonCol>
            </IonRow>
        </IonCardContent>
      </IonCard>
  );
}

export default NotificationCard;
