import React, { useState } from 'react';
import { IonPopover, IonList, IonItem, IonIcon, IonLabel, IonButton, IonButtons } from '@ionic/react';
import {
  createOutline,
  trashOutline,
  ellipsisVertical,
  logInOutline,
  folderOutline,
  listOutline,
  downloadOutline, statsChartOutline
} from 'ionicons/icons';
import {  subProjectService } from '../../services';
import { useHistory } from 'react-router';
import { useToast } from '../../contexts/ToastContext';
import axios from '../../axios';

const SubProjectMenu: React.FC<any> = ({ projectId, subProjectId, getSubProjectList, fromDetails = false  }: {
  projectId: string
  subProjectId: string
  getSubProjectList: any
  fromDetails?: boolean
}) => {
  const history = useHistory();
  const toast = useToast();
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  const access = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    if (fromDetails) {
      localStorage.setItem('fromDetailReditect', "doit")
    }
    history.push(`/projet/${projectId}/sous-projet/${subProjectId}/acces`);
  }

  const edit = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    if (fromDetails) {
      localStorage.setItem('fromDetailReditect', "doit")
    }
    history.push(`/projet/${projectId}/sous-projet/${subProjectId}/editer`);
  }


  const doc = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    if (fromDetails) {
      localStorage.setItem('fromDetailReditect', "doit")
    }
    history.push(`/projet/${projectId}/sous-projet/${subProjectId}/documentation`);
  }

  const reportPowerBI = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    history.push(`/projet/${projectId}/sous-projet/${subProjectId}/report-powerbi`);
  }

  const rex = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    history.push(`/projet/${projectId}/sous-projet/${subProjectId}/rex`);
  }

  const mileStones = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    history.push(`/projet/${projectId}/sous-projet/${subProjectId}/milestones`);
  }

  const exportReport = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    axios.get(`sub_projects/${subProjectId}/export-tasks`, {
        responseType: 'blob'
      }).then(data => {
        const blobURL = window.URL.createObjectURL(data.data);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', 'rapport-avancement.csv');
        tempLink.click();
        tempLink.remove();
    })
  }

  const remove = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    subProjectService
      .delete(subProjectId)
      .then((projectResponse) => {
        if (getSubProjectList) {
          getSubProjectList(projectId)
        }
        toast.show('success', "Le sous-projet a bien été supprimé");
        history.push(`/projet/${projectId}`);
      }).catch(error => {
        toast.show('error', "La suppression du sous-projet a échoué");
      });
  }

  return (<>
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={(e) =>
          setShowPopover({ open: false, event: undefined })
        }
        cssClass="popover-menu"
      >
        <IonList>
          <IonItem routerDirection='none' routerLink="#" onClick={access}>
            <IonIcon icon={logInOutline} slot="start" />
            <IonLabel>Accès</IonLabel>
          </IonItem>
          <IonItem routerDirection='none' routerLink="#" onClick={edit}>
            <IonIcon icon={createOutline} slot="start" />
            <IonLabel>Modifier</IonLabel>
          </IonItem>

          <IonItem routerDirection='none' routerLink="#" onClick={doc}>
            <IonIcon icon={folderOutline} slot="start" />
            <IonLabel>Documentation</IonLabel>
          </IonItem>

          <IonItem routerDirection='none' routerLink="#" onClick={reportPowerBI}>
            <IonIcon icon={statsChartOutline} slot="start" />
            <IonLabel>Rapport PowerBI</IonLabel>
          </IonItem>

          <IonItem routerDirection='none' routerLink="#" onClick={exportReport}>
            <IonIcon icon={downloadOutline} slot="start" />
            <IonLabel>Exporter l'avancement</IonLabel>
          </IonItem>

          <IonItem routerDirection='none' routerLink="#" onClick={rex}>
            <IonIcon icon={listOutline} slot="start" />
            <IonLabel>Liste des commentaires</IonLabel>
          </IonItem>

          <IonItem routerDirection='none' routerLink="#" onClick={mileStones}>
            <IonIcon icon={listOutline} slot="start" />
            <IonLabel>Tâches jalon</IonLabel>
          </IonItem>

          <IonItem routerDirection='none' routerLink="#" onClick={remove}>
            <IonIcon color="danger" icon={trashOutline} slot="start" />
            <IonLabel color="danger">Supprimer</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>
      <IonButtons>
        <IonButton
          color={'primary'}
          style={{ position: "relative" }}
          onClick={(e) =>
            setShowPopover({ open: true, event: e.nativeEvent })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisVertical} />
        </IonButton>
      </IonButtons>
    </>
  );
};

export default SubProjectMenu;
