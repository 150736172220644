import http from '../../HttpService'
import axios from '../../../axios'

const dataType = `projects`
const baseService = http(dataType)
const additionalRequest = {
    delete: (id: string) => axios.put(`${dataType}/${id}`, { deleted: true }),
    getSubProject: (id: string) => axios.get(`${dataType}/${id}/sub_projects`),
}

export default Object.assign(baseService, additionalRequest)
