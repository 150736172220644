import { IonContent, IonHeader, IonPage, IonToolbar, useIonViewWillEnter, IonGrid, IonToggle, IonRow } from "@ionic/react";
import React, { useState, useRef } from "react";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import AddFilter from "../../../components/Header/AddFilter/AddFilter";
import {subProjectService, userService} from "../../../services";
import EmptyList from "../../../components/EmptyList";
import "./SubProjectDocumentation.scss";
import DocumentCard from "../../../components/Document/DocumentCard";

const List = ({ list, subProjectId, getDocumentList, isEditable }: any) => <>
  {list.map((document: any) => <DocumentCard key={document.ref} subProjectId={subProjectId} isEditable={isEditable} document={document} getDocumentList={getDocumentList} />)}
</>

const SubProjectDocumentation: React.FC = () => {
  const isMountedRef = useRef<null | boolean>(null);
  const [isEditionMode, setEditionMode] = useState<boolean>(false);
  const [permissionList, setPermissionList]: any = useState([]);

  const getPermissionList = (subProjectId: any) => {
    subProjectService.getCurrentPermissionList(subProjectId)
        .then(({ data: permissionListData }: any) => {
          setPermissionList(Object.values(permissionListData));
        })
        .catch(console.log);
  }

  // document.title = 'Modifier le projet - Canreport'

  const projectId = window.location.pathname.split('/').reverse()[3]!;
  const [subProject, setSubProject]: any = useState({});

  const [documentListDisplayed, setDocumentListDisplayed]: any = useState([])

  const getDocumentList = (subProjectId: string) => {
    subProjectService.getDocumentList(subProjectId).then(({ data: documentData }) => {
      if (isMountedRef.current) {
        const parsed = Object.keys(documentData).map((key, index) => ({
          ref: key,
          documents: documentData[key]
        }));

        setDocumentListDisplayed(parsed) //tmp
      }
    }).catch(e => {})
  }

  useIonViewWillEnter(() => {
    isMountedRef.current = true;
    const subProjectId = window.location.pathname.split('/').reverse()[1]!

    subProjectService.getOne(subProjectId)
      .then(({ data: subProjectData }: any) => {
        document.title = `${subProjectData.name} (documentation) - Canreport`
        setSubProject(subProjectData)
      })
      .catch(console.log);

    getDocumentList(subProjectId)
    getPermissionList(subProjectId);
    return () => isMountedRef.current = false;
  }, [])

  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu active="projets" backLabel={subProject.name} backLink={`/projet/${projectId}/sous-projet/${subProject.id}`} />
          <TitleOptionSearch
            title="Documentation"
            // onSearchChange={onSearchChange}
            // optionType="documentation"
            projectId={projectId}
            subProjectId={subProject.id}
            // fromDetails={true}
          />
          {(userService.isAdmin() || permissionList.some((e: any) => e.permissions.includes('documentation'))) && <IonGrid className="center-content">
              <IonRow className={"ion-align-items-center"}>
                <p>Mode édition</p>
                <IonToggle checked={isEditionMode} onIonChange={(e: any) => setEditionMode(e.detail.checked)}/>
              </IonRow>
            </IonGrid>}
          {isEditionMode && <AddFilter
            zipImport={true}
            subProjectId={subProject.id}
            getDocumentList={getDocumentList}
          />}
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <List list={documentListDisplayed} subProjectId={subProject.id} getDocumentList={getDocumentList} isEditable={isEditionMode} />
          <EmptyList list={documentListDisplayed} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SubProjectDocumentation;
