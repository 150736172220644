import React, { useState } from 'react';
import { IonPopover, IonList, IonItem, IonIcon, IonLabel, IonButton, IonButtons } from '@ionic/react';
import { createOutline, eyeOffOutline, trashOutline, ellipsisVertical, eyeOutline } from 'ionicons/icons';
import { userService } from '../../services';
import { useHistory } from 'react-router';
import { useToast } from "../../contexts/ToastContext";

const UserMenu: React.FC<any> = ({ userId, isEnabled, getUserList, fromDetails = false }: {
  userId: string
  isEnabled: boolean
  getUserList: any
  fromDetails?: boolean
}) => {
  const history = useHistory();
  const toast = useToast();
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  const edit = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    if (fromDetails) {
      localStorage.setItem('fromDetailReditect', "doit")
    }
    history.push(`/utilisateur/${userId}/editer`);
  }

  const toogleEnable = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    userService.put(userId, {
      enabled: !isEnabled
    }).then((userResponse) => {
      // setRequireUpdate(true)
      if (getUserList) {
        getUserList()
      }
      // actualisé la liste
    }).catch(error => {
      console.error(error)
    });
  }

  const remove = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    userService.delete(userId)
    .then((userResponse) => {
      if (getUserList) {
        getUserList()
      }
      toast.show('success', "L'utilisateur a bien été supprimé");
    }).catch(error => {
      toast.show('error', "La suppression de l'utilisateur a échoué");
    });
  }

  const DisplayEnableStatusChange = () => {
    return isEnabled ?
      <IonItem routerDirection='none' routerLink="#" onClick={toogleEnable}>
        <IonIcon icon={eyeOffOutline} slot="start" />
        <IonLabel>Désactiver</IonLabel>
      </IonItem>
      :
      <IonItem routerDirection='none' routerLink="#" onClick={toogleEnable}>
        <IonIcon icon={eyeOutline} slot="start" />
        <IonLabel>Activer</IonLabel>
      </IonItem>
  }

  return (<>
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={(e) =>
          setShowPopover({ open: false, event: undefined })
        }
        cssClass="popover-menu"
      >
        <IonList>
          <IonItem routerDirection='none' routerLink="#" onClick={edit}>
            <IonIcon icon={createOutline} slot="start" />
            <IonLabel>Modifier</IonLabel>
          </IonItem>
          <DisplayEnableStatusChange />
          <IonItem routerDirection='none' routerLink="#" onClick={remove}>
            <IonIcon color="danger" icon={trashOutline} slot="start" />
            <IonLabel color="danger">Supprimer</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>
      <IonButtons>
        <IonButton
          style={{ position: "relative" }}
          onClick={(e) =>
            setShowPopover({ open: true, event: e.nativeEvent })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisVertical} />
        </IonButton>
      </IonButtons>
    </>
  );
};

export default UserMenu;
