import React, { useState, useCallback } from "react";
import { IonButtons, IonGrid, IonRow, IonButton, IonIcon, IonLabel, IonText } from "@ionic/react";
import { funnelOutline, chevronDownOutline, downloadOutline, closeCircleOutline } from "ionicons/icons";
import "./AddFilter.scss";
import Select from "react-select";
import { subProjectService, userService } from "../../../services";
import { useDropzone } from "react-dropzone";
import {useToast} from "../../../contexts/ToastContext";

const OptionsList = ({
    options,
}: {
    options: [
        {
            label: string;
            value: string;
        }
    ];
}) => (
    <>
        {options.map((option: any) => (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
        ))}
    </>
);

const AddFilter: React.FC<any> = ({ type = null, zipImport, subProjectId, getDocumentList, addLink, addLabel, addIcon, list, bookletOptions, onBookletChange, onFilterChange, options }) => {
    const toast = useToast();
    const LSTest = localStorage.getItem(`${type}filter`)
    if (!LSTest || LSTest === 'null') {
        localStorage.setItem(`${type}filter`, "all");
    }
    const filterName = localStorage.getItem(`${type}filter`)!;

    const defaultFileLabel = 'Import via .zip'
    const [zipImportLabel, setZipImportLabel] = useState<string>(defaultFileLabel);
    const [fileImportHasError, setFileImportHasError] = useState<boolean>(false);

    // Feedback that the file is correct and will be used
    const onDropAccepted = useCallback(acceptedFiles => {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        subProjectService.postImportZip(subProjectId, formData).then((data) => {
            toast.show('success', "L'import du ZIP s'est bien effectué");
            if (getDocumentList) {
                getDocumentList(subProjectId)
            }
        })
        .catch(error => {
            toast.show('error', "L'import du ZIP a échoué");
        })
    }, [subProjectId, getDocumentList, toast])

    const onDropRejected = useCallback(rejectedFiles => {
        const errorCode = rejectedFiles[0].errors[0].code;
        const errorTimeout = 4000;
        setFileImportHasError(true);
        if (errorCode === 'too-many-files' || errorCode === 'file-invalid-type') {
            setZipImportLabel(`Importer uniquement un fichier zip`);
        }

        setTimeout(() => {
            setZipImportLabel(defaultFileLabel);
            setFileImportHasError(false);
        }, errorTimeout)
    }, [])

    const {
        getRootProps,
        getInputProps
    } = useDropzone({onDropAccepted, onDropRejected,
        accept: 'application/zip,application/x-zip-compressed',
        multiple: false
    })

    return (
        <IonButtons className="add-filter">
            <IonGrid>
                <IonRow className="ion-justify-content-between">
                    {addLabel && userService.isAdmin() &&
                        <IonButton routerDirection="none" routerLink={addLink}>
                            <IonIcon slot="start" icon={addIcon} />
                            <IonLabel style={{ textTransform: "none" }}>{addLabel}</IonLabel>
                        </IonButton>
                    }
                    {!userService.isAdmin() && <div></div>}
                    {bookletOptions && <div
                        style={{
                            minWidth: "300px",
                        }}
                    >
                        <Select
                            defaultValue={[bookletOptions[2], bookletOptions[3]]}
                            isMulti
                            name="colors"
                            options={bookletOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>}
                    {zipImport && <>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <IonButton>
                                <IonIcon slot="start" icon={fileImportHasError ? closeCircleOutline : downloadOutline} />
                                <IonLabel style={{ textTransform: "none" }}>{zipImportLabel}</IonLabel>
                            </IonButton>
                        </div>
                        <IonText style={{ alignSelf: 'center' }}>
                            Glissez et déplacez vos fichiers .pdf ou .png ici ou cliquez pour parcourir
                        </IonText>
                    </>}
                    {type && <IonButton>
                        <IonIcon slot="start" icon={funnelOutline} />
                        <select
                            onChange={(e) => onFilterChange(e.target.value)}
                            defaultValue={filterName}
                            className="filter"
                        >
                            <OptionsList options={options} />
                        </select>
                        <IonIcon slot="end" icon={chevronDownOutline} />
                    </IonButton>
                    }
                </IonRow>
            </IonGrid>
        </IonButtons>
    );
};

export default AddFilter;
