import "./ForgotPassword.scss";
import { keyOutline, mailOutline } from "ionicons/icons";
import AuthenticationForm from "../../../components/Authentication/AuthenticationForm";
import { IonPage, IonContent } from "@ionic/react";
import React from "react";

const ForgotPassword: React.FC = () => {
  document.title = 'Authentification - Canreport'

  return (
    <IonPage>
      <IonContent>
        <AuthenticationForm
          type='forgotPassword'
          title="Mot de passe oublié ?"
          titleIcon={keyOutline}
          rememberMe={false}
          primaryButtonLabel="Envoyer"
          primaryButtonIcon={mailOutline}
          secondaryButtonLabel="Retour"
          secondaryButtonLink="/connexion"
          registration={{
            email: true,
            password: false,
            validatePassword: false,
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
