import axios from "../axios";

// HTTP Module
export default (dataType: string) => {
    return {
        getList: () => axios.get(dataType),
        getOne: (id: string) => axios.get(`${dataType}/${id}`),
        post: (params: any) => axios.post(dataType, params),
        put: (id: string, params: any) => axios.put(`${dataType}/${id}`, params),
        patch: (id: string, params: any) => axios.patch(`${dataType}/${id}`, params),
        delete: (id: string) => axios.delete(`${dataType}/${id}`),
    }
}
