import React from "react";
import useDate from "../../hooks/useDate";
import {IonChip, IonLabel } from "@ionic/react";

const { isWarning, isLate } = useDate();

const Chip: React.FC<any> = ({ task }) => {
    return <>
        <IonChip outline={true} color={chipColor(task)} style={{
            cursor: "default",
            border: 'none',
        }}>
            <IonLabel>{Math.trunc(task.progress || 0)} %</IonLabel>
        </IonChip>
    </>
}

export const chipColor = (task?: any) => {
    const startDate = typeof task?.startDate === 'object' ? task?.startDate.date : task.startDate;
    const endDate = typeof task?.endDate === 'object' ? task?.endDate.date : task.endDate;

    return task?.progress == 100
        ? 'success'
        : isWarning(startDate, endDate)
            ? 'warning'
            : isLate(endDate)
                ? 'danger'
                : task?.progress === 0 ? 'future' : 'primary';
};

export default Chip;
