import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonIcon,
    IonLabel,
    IonRow,
    IonTextarea,
} from "@ionic/react";
import {arrowBackOutline} from "ionicons/icons";
import Select from "react-select";
import './EditRexModal.scss';
import SubProjectService from "../../../services/Api/Project/SubProjectService";
import axios from "../../../axios";

const EditRexModal: React.FC<any> = ({ rex = null, subProject, onDismiss, saveTask }) => {

    const [categories, setCategories] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [alert, setAlert] = useState<string>('');

    useEffect(() => {
        if(alert !== '') {
            setTimeout(() => {
                setAlert('');
            }, 2000);
        }
    },[alert]);

    useEffect(() => {
        axios.get('config/rex_categories').then(data => {
            setCategories(data.data.map((d: any) => ({
                label: d,
                value: d
            })));
        })
    }, []);

    useEffect(() => {
        SubProjectService.getUsers(subProject.id).then((data: any) => {
            setUsers(data.data.map((d: any) => ({
                label: d.fullName,
                value: d.id
            })));
        })
    }, [subProject])

    const IMAGE_NAMES = ['imageOne', 'imageTwo', 'imageThree'];

    const [rexData, setRexData] = useState<any>(rex ?? {
        recipients: [],
    });

    const edit = (field: string, value: any) => {
        setRexData((rexData: object) => ({...rexData, [field]: value}));
    }

    useEffect(() => {
        if(categories.length !== 0) {
            if(rex !== null) {
                setRexData({
                    ...rex,
                    category: categories.find(c => c.value === rex.category),
                });
            } else {
                setRexData({});
            }
        }
    }, [rex, categories]);

    const getBase64 = (file: any) => {
        return new Promise((resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }))
    }

    const save = () => {
        const clone = {...rexData};

        if('category' in rexData && 'comment' in rexData) {
            clone.category = rexData.category.value;
            clone.recipients = rexData?.recipients?.map((v: any) => v.value);
            if(clone.imageOne && !clone.imageOne.startsWith('data:')) {
                delete clone.imageOne;
            }
            if(clone.imageTwo && !clone.imageTwo.startsWith('data:')) {
                delete clone.imageTwo;
            }
            if(clone.imageThree && !clone.imageThree.startsWith('data:')) {
                delete clone.imageThree;
            }
            saveTask(clone);
        } else {
            setAlert('Veuillez définir une catégorie et un commentaire');
        }
    }

    return (
        <div>
            <IonRow className={'ion-justify-content-between ion-align-items-center'}>
                <IonButton onClick={onDismiss}>
                    <IonIcon icon={arrowBackOutline} />
                </IonButton>
                <p>{rex !== null ? 'Edition' : 'Ajout'} d'un commentaire</p>
            </IonRow>

            <div>
                <div className="form-group">
                    <IonLabel color={'dark'}>Catégorie</IonLabel>
                    <Select value={rexData?.category}
                            className={'input'}
                            required={true}
                            onChange={(value: any) => {
                                edit('category', value);
                            }}
                            options={categories} />
                </div>

                <div className="form-group">
                    <IonLabel color={'dark'}>Commentaire</IonLabel>
                    <IonTextarea name="comment"
                              className={'input'}
                              color={'dark'}
                              required={true}
                              value={rexData?.comment}
                                 onIonChange={(e: any) => {
                                     edit('comment', e.target.value);
                                 }}
                              onChange={(value: any) => {
                                 edit('comment', value);
                              }}
                              placeholder={'Commentaire'}
                    />
                </div>

                <div className="form-group">
                    <IonLabel color={'dark'}>Images</IonLabel>
                    <input multiple type={"file"}
                           accept="image/png,image/jpeg"
                           max={3}
                           required={true}
                           onChange={e => {
                               if(e.target.files) {
                                   Array.from(e.target.files).forEach(async (file, index) => {
                                       if(index < IMAGE_NAMES.length) {
                                           edit(IMAGE_NAMES[index], await getBase64(file));
                                       }
                                   });
                               }
                           }}
                    />

                    <div>
                        {IMAGE_NAMES.filter(n => n in rexData && rexData[n] !== null && rexData[n] !== undefined).map((n, i) => <img key={i} src={rexData[n]} width={100} height={100} />)}
                    </div>
                </div>

                {rex === null && <div className="form-group">
                    <IonLabel color={'dark'}>Notifier la ou les personnes</IonLabel>
                    <Select value={rexData?.recipients}
                            className={'input'}
                            isMulti={true}
                            onChange={(value: any) => {
                                edit('recipients', value);
                            }}
                            options={users} />
                </div>}

                <IonButton onClick={save}>
                    Sauvegarder
                </IonButton>

                {alert !== '' && <p className="sc-ion-label-md-h sc-ion-label-md-s ion-color ion-color-danger">{alert}</p>}
            </div>
        </div>
    );
}

export default EditRexModal;
