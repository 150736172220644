import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import React from "react";
// import "./NotFound.css";

const NotFound: React.FC = () => {
  return (
      <IonPage className="ion-page" id="main-content">
        <IonHeader>
          <IonToolbar>
          </IonToolbar>
        </IonHeader>
        <IonContent className="IonPadding">
          <h2>Erreur 404</h2>
          <p>Page introuvable</p>
        </IonContent>
      </IonPage>
  );
};

export default NotFound;
