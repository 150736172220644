import React, { useState, useCallback } from "react";
import { IonCard, IonCardHeader, IonRow, IonCardSubtitle, IonLabel, IonIcon, IonCardContent } from "@ionic/react";
import DocumentItem from "./DocumentItem";
import { useDropzone } from "react-dropzone";
import { subProjectService } from "../../services";
import {cloudUploadOutline, closeCircleOutline, downloadOutline} from "ionicons/icons";

const DocumentCard: React.FC<any> = ({ subProjectId, document, getDocumentList, isEditable }: {
  subProjectId: string
  document: any
  getDocumentList: any,
  isEditable: boolean
}) => {
  const defaultFileLabel = ''
  const [fileLabel, setFileLabel] = useState<string>(defaultFileLabel);
  const [fileImportHasError, setFileImportHasError] = useState<boolean>(false);

  const [filesUploading, setFilesUploading] = useState<any>({});

  // Feedback that the file is correct and will be used
  const onDropAccepted = useCallback(acceptedFiles => {
    Promise.all(acceptedFiles.map((file: any) => {
      const formData = new FormData();
      formData.append('file', file);
      filesUploading[file.name] = 0;
      setFilesUploading(filesUploading);

      return subProjectService.postImportFile(subProjectId, document.ref, formData, (progress: number) => {
          filesUploading[file.name] = progress;
          setFilesUploading(filesUploading);
          if(progress === 100) {
            setTimeout(() => {
              delete filesUploading[file.name];
            }, 2000);
          }
      })
    })).then((data) => {
      if (getDocumentList) {
        getDocumentList(subProjectId)
      }
    }).catch(e => {})
  }, [subProjectId, document, getDocumentList])

  // Feedback that the import is incorrect
  const onDropRejected = useCallback(rejectedFiles => {
    const errorCode = rejectedFiles[0].errors[0].code;
    const errorTimeout = 4000;
    setFileImportHasError(true);
    if (errorCode === 'file-invalid-type') {
      if (rejectedFiles.length === 1) {
        setFileLabel(`Un fichier n'a pas été importé (uniquement fichier PDF ou PNG accepté)`);
      } else {
        setFileLabel(`Plusieurs fichiers n'ont pas été importés (uniquement fichier PDF ou PNG accepté)`);
      }
    }

    setTimeout(() => {
      setFileLabel(defaultFileLabel);
      setFileImportHasError(false);
    }, errorTimeout)
  }, [])

  const {
    getRootProps,
    getInputProps,
    // rootRef, // Ref to the `<div>`
    // inputRef, // Ref to the `<input>`
    isDragActive
  } = useDropzone({onDropAccepted, onDropRejected,
    accept: 'application/pdf, image/png',
    multiple: true
  })

  const getCardContent = () => {
    return (
        <div>
          <IonCardHeader>
            <IonRow className="ion-align-items-center ion-justify-content-between">
              <IonCardSubtitle style={{width: "100%"}}>
                <IonRow className="ion-align-items-center ion-justify-content-between">
                  <IonLabel>
                    {document.ref}
                  </IonLabel>
                  {isEditable && <div {...getRootProps({
                    style: {
                      cursor: 'pointer',
                    }
                  })}>
                    <IonRow className={"ion-align-items-center"}>
                      {Object.entries(filesUploading).length > 0 && <div style={{ width: 150, height: 20, borderRadius: 3, backgroundColor: '#d9d9d9', marginRight: 15 }}>
                        <div style={{ width: (Object.values(filesUploading).reduce<number>((a, b: any) => a+b, 0)/Object.entries(filesUploading).length) + '%', height: '100%', backgroundColor: '#00b300', borderRadius: 3 }}>

                        </div>
                      </div>}

                      <IonIcon size={"large"} icon={cloudUploadOutline}/>
                      <input {...getInputProps()} />
                    </IonRow>
                  </div>}
                  {isDragActive && <IonLabel><IonIcon icon={downloadOutline} style={{
                    marginRight: '4px',
                    position: 'relative',
                    top: '1px'
                  }}/>Importer le(s) document(s) ...</IonLabel>}
                  {fileImportHasError && <IonLabel><IonIcon icon={closeCircleOutline} style={{
                    marginRight: '4px',
                    position: 'relative',
                    top: '1px'
                  }}/>{fileLabel}</IonLabel>}
                </IonRow>
              </IonCardSubtitle>
            </IonRow>
          </IonCardHeader>
          {
            document.documents &&
            <IonCardContent>
              {document.documents.map((documentItem: any) => <DocumentItem key={documentItem.id} isEditable={isEditable}
                                                                           document={documentItem}/>)}
            </IonCardContent>
          }
        </div>);
  }

  return (
    <IonCard className="document-card">
      {getCardContent()}
    </IonCard>
  )
};

export default DocumentCard;
