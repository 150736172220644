
import { IonContent, IonHeader, IonPage, IonLabel, IonToolbar, useIonViewWillEnter, IonGrid } from "@ionic/react";
import React, { useState,  useRef } from "react";
import "./SubProjectList.scss";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import AddFilter from "../../../components/Header/AddFilter/AddFilter";
import { addOutline } from "ionicons/icons";
import useFilter from "../../../hooks/useFilter";
import useSearch from "../../../hooks/useSearch";
import useDataFlow from "../../../hooks/useDataFlow";
import SubProjectCard from "../../../components/SubProject/SubProjectCard";
import { projectService } from "../../../services";

const List = ({ projectId, list, getSubProjectList }: any) => <>
  {list.map((subProject: any) => <SubProjectCard key={subProject.id} projectId={projectId} subProject={subProject} getSubProjectList={getSubProjectList}/>)}
</>

const SubProjectList: React.FC = () => {
  const isMountedRef = useRef<null | boolean>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject]: any = useState({});
  // const [isSearching, setIsSearching]: any = useState();
  const [subProjectListData, setSubProjectListData] = useState([])
  const [subProjectListDisplayed, setSubProjectListDisplayed] = useState([])
  const { triggerSearch } = useSearch('subproject', setSubProjectListDisplayed)
  const { triggerFilter, options } = useFilter('subproject')
  const { initDataFlow, dataFlow } = useDataFlow('subproject', subProjectListData, [triggerFilter, triggerSearch])

  const onFilterChange = (text: string) => {
    dataFlow([
      text,
      localStorage.getItem('search')!
    ])
  }

  const onSearchChange = (text: string) => {
    // setIsSearching(text !== '')
    dataFlow([
      localStorage.getItem('subprojectfilter')!,
      text
    ])
  }

  const getSubProjectList = (projectId: string) => {
    projectService.getSubProject(projectId).then(response => {
      if (isMountedRef.current) {
        setSubProjectListData(response.data)
        const searchText = ''
        localStorage.setItem('search', searchText)
        initDataFlow([
          localStorage.getItem('subprojectfilter')!,
          searchText
        ], response.data)
        setIsLoading(false)
      }
    }).catch(e => {})
  }

  useIonViewWillEnter(() => {
    isMountedRef.current = true;
    const projectId = window.location.pathname.split('/').slice(-1).pop()!

    projectService.getOne(projectId)
      .then(({ data }) => {
        document.title = `${data.name} - Canreport`
        setProject(data)
        // setIsDisplayReady(true)
      })
      .catch(console.log);

    getSubProjectList(projectId)
    return () => isMountedRef.current = false;
  }, [])

  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu active="projets" backLabel="Projets" backLink="/projets" />
          <TitleOptionSearch
            title={project.name}
            listCount={subProjectListDisplayed.length || "0"}
            onSearchChange={onSearchChange}
            optionType="project"
            projectId={project.id}
            isArchived={project.archived}
            fromDetails={true}
          />
          <AddFilter
            type="subproject"
            addLink={`/projet/${project.id}/sous-projets/creer`}
            addLabel="Créer un sous-projet"
            addIcon={addOutline}
            onFilterChange={onFilterChange}
            options={options}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <List projectId={project.id} list={subProjectListDisplayed} getSubProjectList={getSubProjectList} />
          {/* <EmptyList list={subProjectListDisplayed} isSearching={isSearching} /> */}
          {subProjectListDisplayed.length === 0 && !isLoading && <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '150px',
          }}>
            <IonLabel style={{marginBottom: '20px'}}>
              Aucun sous-projet
            </IonLabel>
          </div>}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SubProjectList;
