import React from "react";
import { Redirect, Route } from 'react-router-dom'

import Login from './pages/authentication/Login/Login';
import ForgotPassword from './pages/authentication/ForgotPassword/ForgotPassword';
import NewPassword from './pages/authentication/NewPassword/NewPassword';
import ChangePassword from './pages/profil/ChangePassword/ChangePassword';
import ProjectList from './pages/project/List/ProjectList';
import CreateProject from './pages/project/Create/CreateProject';
import EditProject from './pages/project/Edit/EditProject';
import SubProjectList from './pages/subProject/List/SubProjectList';
import EditSubProject from './pages/subProject/Edit/EditSubProject';
import CreateSubProject from './pages/subProject/Create/CreateSubProject';
import SubProjectAcces from './pages/subProject/Access/SubProjectAcces';
import SubProjectDetails from './pages/subProject/Details/SubProjectDetails';
import SubProjectDocumentation from './pages/subProject/Documentation/SubProjectDocumentation';
import UserList from './pages/user/List/UserList';
import CreateUser from './pages/user/Create/CreateUser';
import UserDetails from './pages/user/Details/UserDetails';
import EditUser from './pages/user/Edit/EditUser';
import NotFound from './pages/NotFound'

import { IonReactRouter } from "@ionic/react-router";
import { IonRouterOutlet } from "@ionic/react";

import { AuthContext } from './App';
import { userService } from "./services";
import Testing from "./pages/Testing";
import ReturnOfExperienceList from "./pages/subProject/ReturnOfExperience/ReturnOfExperienceList";
import MileStoneTaskList from "./pages/subProject/MileStoneTask/MileStoneTaskList";
import NotificationList from "./pages/notification/List/NotificationList";
import {ReportPowerBI} from "./pages/subProject/ReportPowerBI/ReportPowerBI";

const AppRouter: React.FC = () => {
  const { state }: any = React.useContext(AuthContext);
    //@ts-ignore

  const PublicRoute = ({ component: Component, ...rest }: any) => {
      return (
        <Route
            {...rest}
            render={props =>
                state.isAuthenticated
                    ? <Redirect to="/" />
                    : <Component {...props} />
            }
        />);
  }

  const PrivateRoute = ({ component: Component, onlyAdmin = false, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={props =>
              state.isAuthenticated ?
                 !onlyAdmin || userService.isAdmin()
                     ? <Component {...props} />
                     : <Redirect to="/" />
                : <Redirect to={'/connexion'} />
            }
        />
    );
  }

  return (
    <IonReactRouter>
        <IonRouterOutlet animated={false}>
          {/* Authentication */}
          <PublicRoute path="/connexion" component={Login} exact={true} />
          <PublicRoute path="/mot-de-passe-oublie" component={ForgotPassword} exact={true} />
          <Route path="/nouveau-mot-de-passe/:email/:token" component={NewPassword} exact={true} />

          {/* <PrivateRoute path="/changer-email" component={ChangeEmail} exact={true} /> */}
          <PrivateRoute path="/changer-mot-de-passe" component={ChangePassword} exact={true} />

        {/* Project */}
        <PrivateRoute path="/projets" component={ProjectList} exact={true} />
        <PrivateRoute onlyAdmin={true} path="/projets/creer" component={CreateProject} exact={true} />
        <PrivateRoute path="/projet/:projectid" component={SubProjectList} exact={true} />
        <PrivateRoute onlyAdmin={true} path="/projet/:projectid/editer" component={EditProject} exact={true} />

        {/* Sub-project */}
        <PrivateRoute onlyAdmin={true} path="/projet/:projectid/sous-projets/creer" component={CreateSubProject} exact={true} />
        <PrivateRoute path="/projet/:projectid/sous-projet/:subprojectid" component={SubProjectDetails} exact={true} />
        <PrivateRoute onlyAdmin={true} path="/projet/:projectid/sous-projet/:subprojectid/editer" component={EditSubProject} exact={true} />
        <PrivateRoute path="/projet/:projectid/sous-projet/:subprojectid/acces" component={SubProjectAcces} exact={true} />
        {/* Sub-project docs */}
        <PrivateRoute path="/projet/:projectid/sous-projet/:subprojectid/documentation" component={SubProjectDocumentation} exact={true} />
        <PrivateRoute path="/projet/:projectid/sous-projet/:subprojectid/report-powerbi" component={ReportPowerBI} exact={true} />
        <PrivateRoute path="/projet/:projectid/sous-projet/:subprojectid/rex" component={ReturnOfExperienceList} exact={true} />
        <PrivateRoute path="/projet/:projectid/sous-projet/:subprojectid/milestones" component={MileStoneTaskList} exact={true} />

        {/* Users */}
        <PrivateRoute onlyAdmin={true} path="/utilisateurs" component={UserList} exact={true} />
        <PrivateRoute onlyAdmin={true} path="/utilisateurs/creer" component={CreateUser} exact={true} />
        <PrivateRoute onlyAdmin={true} path="/utilisateur/:userid" component={UserDetails} exact={true} />
        <PrivateRoute onlyAdmin={true} path="/utilisateur/:userid/editer" component={EditUser} exact={true} />
        <PrivateRoute onlyAdmin={false} path="/utilisateur/notifications" component={NotificationList} exact={true} />

        <PrivateRoute path="/testing" component={Testing} exact={true} />

        <Route exact={true} path="/" render={() => <Redirect to={state.isAuthenticated ? '/projets' : '/connexion'} />} />

        <Route render={() => state.isAuthenticated ? <NotFound /> : <Redirect to='/connexion' />} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
}

export default AppRouter;
