import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token')!)

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common = {
    'Content-Type': `application/json`,
    'Authorization': token ? `Bearer ${token}` : ``
}

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        if(!window.location.pathname.endsWith('connexion')) {
            window.location.assign( 'connexion');
        }
        localStorage.clear();

    }
    return Promise.reject(error)
});

export default axios;
