
import axios from "../../../axios";
import jwt_decode from "jwt-decode";

const auth = {
  login: (params: any) => {
    return new Promise((revolve, reject) => {
        axios
          .post(`/auth/login_check`, params)
          .then((tokenResponse) => {
            const JWTPayload: any = jwt_decode(tokenResponse.data.token);

            axios.defaults.headers.common = {
              "Content-Type": `application/json`,
              Authorization: `Bearer ${tokenResponse.data.token}`,
            };
            revolve({ tokenResponse, JWTPayload })
          })
          .catch(reject);
    })
  },
  rereshToken: (params: any) => axios.post(`/auth/token/refresh`, params),
  checkToken: (params: any) => axios.post(`/user_token​/check`, params),
  updatePassword: (params: any) => axios.post(`/user_password/update`, params),
  forgotPassword: (params: any) => axios.post(`/auth/forgot_password`, params),
};

export default auth;
