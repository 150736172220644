import React, { useState } from 'react';
import { IonPopover, IonList, IonItem, IonIcon, IonLabel, IonButton, IonButtons } from '@ionic/react';
import { createOutline, trashOutline, ellipsisVertical, archiveOutline } from 'ionicons/icons';
import { projectService } from '../../services';
import { useHistory } from 'react-router';
import { useToast } from '../../contexts/ToastContext';

const ProjectMenu: React.FC<any> = ({ projectId, isArchived, getProjectList, fromDetails = false }: {
  projectId: string
  isArchived: boolean
  getProjectList?: any
  fromDetails: boolean
}) => {
  const history = useHistory();
  const toast = useToast();
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  const edit = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    if (fromDetails) {
      localStorage.setItem('fromDetailReditect', "doit")
    }
    history.push(`/projet/${projectId}/editer`);
  }

  const toogleArchived = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    projectService.put(projectId, {
      archived: !isArchived
    }).then((projectResponse) => {
      // setRequireUpdate(true)
      if (getProjectList) {
        getProjectList()
      }
      // actualisé la liste
    }).catch(e => {})
  }

  const remove = (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    setShowPopover({ open: false, event: undefined })
    projectService
      .delete(projectId)
      .then((projectResponse) => {
        if (getProjectList) {
          getProjectList()
        }
        toast.show('success', "Le projet a bien été supprimé");
        history.push('/projets')
      }).catch(error => {
        toast.show('error', "La suppression du projet a échoué");
      });
  }

  const DisplayEnableStatusChange = () => {
    return isArchived ?
      <IonItem routerDirection='none' routerLink="#" onClick={toogleArchived}>
        <IonIcon icon={archiveOutline} slot="start" />
        <IonLabel>Désarchiver</IonLabel>
      </IonItem>
      :
      <IonItem routerDirection='none' routerLink="#" onClick={toogleArchived}>
        <IonIcon icon={archiveOutline} slot="start" />
        <IonLabel>Archiver</IonLabel>
      </IonItem>
  }

  return (<>
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={(e) =>
          setShowPopover({ open: false, event: undefined })
        }
        cssClass="popover-menu"
      >
        <IonList>
          <IonItem routerDirection='none' routerLink="#" onClick={edit}>
            <IonIcon icon={createOutline} slot="start" />
            <IonLabel>Modifier</IonLabel>
          </IonItem>
          <DisplayEnableStatusChange />
          <IonItem routerDirection='none' routerLink="#" onClick={remove}>
            <IonIcon color="danger" icon={trashOutline} slot="start" />
            <IonLabel color="danger">Supprimer</IonLabel>
          </IonItem>
        </IonList>
      </IonPopover>
      <IonButtons>
        <IonButton
          style={{ position: "relative" }}
          onClick={(e) =>
            setShowPopover({ open: true, event: e.nativeEvent })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisVertical} />
        </IonButton>
      </IonButtons>
    </>
  );
};

export default ProjectMenu;
