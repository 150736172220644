import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonCheckbox, IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { useState } from "react";


const Testing: React.FC = () => {
  const [isIndeterminate, setIsIndeterminate] = useState<boolean>()
  const [masterCheck, setMasterCheck] = useState<boolean>()
  const [checkBoxList, setCheckBoxList] = useState<any>([
    {
      value:"Esteban Gutmann IV",
      isChecked:false
    },{
      value:"Bernardo Prosacco Jr.",
      isChecked:false
    },{
      value:"Nicholaus Kulas PhD",
      isChecked:false
    },{
      value:"Jennie Feeney",
      isChecked:false
    },{
      value:"Shanon Heaney",
      isChecked:false
    }
  ])

  const checkEvent = (e: any) => {
    // const checkBoxListUpdated = checkBoxList.map((item: any, index: number) => {
    //   if (index === +e.detail.value) item.isChecked = e.detail.checked
    //   return item
    // })

    const checkBoxListUpdated = checkBoxList
    checkBoxListUpdated[+e.detail.value].isChecked = e.detail.checked
    setCheckBoxList(checkBoxListUpdated)

    updateMaster()
  }

  const updateMaster = () => {
    const totalItems = checkBoxList.length;
    const totalChecked = checkBoxList.filter((item: any) => item.isChecked).length

    if (totalChecked > 0 && totalChecked < totalItems) {
      // If even one item is checked but not all
      setIsIndeterminate(true);
      setMasterCheck(false);
    } else if (totalChecked === totalItems) {
      // If all are checked
      setMasterCheck(true);
      setIsIndeterminate(false);
    } else {
      // If none is checked
      setIsIndeterminate(false);
      setMasterCheck(false);
    }
  }

  const checkMaster = (e: any) => {
    setMasterCheck(e.target.checked)
    setCheckBoxList(checkBoxList.map((item: any) => ({
      ...item,
      isChecked: e.target.checked
    })))
  }

  return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Testing</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel><strong>Select All</strong></IonLabel>
              <IonCheckbox
                slot="start"
                checked={masterCheck}
                indeterminate={isIndeterminate}
                onClick={checkMaster}>
              </IonCheckbox>
            </IonItem>
          </IonList>
          <IonList>
            {checkBoxList.map((item: any, index: number) =>
              <IonItem key={item.value}>
                <IonLabel>{item.value}</IonLabel>
                <IonCheckbox
                  slot="start"
                  value={'' + index}
                  checked={item.isChecked}
                  onIonChange={checkEvent}>
                </IonCheckbox>
              </IonItem>
            )}
          </IonList>
        </IonContent>
        <IonFooter>
        </IonFooter>
      </IonPage>
  );
};

export default Testing;
