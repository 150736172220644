import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonGrid,
  IonRow,
  IonIcon,
  IonCol,
  IonText,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React, { useState } from "react";
import "./UserDetails.scss";
import {  callOutline, briefcaseOutline, personCircleOutline } from "ionicons/icons";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import { userService } from "../../../services";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";

const UserDetails: React.FC<any> = ({ history }) => {
  const [user, setUser]: any = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDisplayReady, setIsDisplayReady] = useState(false);


  useIonViewWillEnter(() => {
    userService.getOne(window.location.pathname.split('/').slice(-1).pop()!)
      .then(({ data }) => {
        document.title = `${data.firstname} ${data.lastname} (details) - Canreport`
        setUser(data)
        setIsDisplayReady(true)
        setIsAdmin(!!data.roles.find((role: string) => role === "ROLE_ADMIN"))
      })
      .catch(console.log);
  }, [user])

  useIonViewWillLeave(() => {
    setIsDisplayReady(false)
  });

  return (
    <IonPage className="ion-page" id="main-content">
        <IonHeader className="center-content">
          <IonToolbar>
            {/* <IonTitle>Header</IonTitle> */}
            {/* <Header display={{ option: true, optionType: 'user' }} active="utilisateurs" title={`${user.firstname} ${user.lastname}`} backLabel="Utilisateurs" backLink="/utilisateurs" /> */}
            <NavigationMenu active="utilisateurs" backLabel="Utilisateurs" backLink="/utilisateurs" />
            {isDisplayReady &&
              <TitleOptionSearch
                optionType="user"
                title={`${user.firstname} ${user.lastname}`}
                userId={user.id}
                isUserEnabled={user.enabled}
                fromDetails={true}
              />
            }
            </IonToolbar>
        </IonHeader>
      {isDisplayReady &&
        <IonContent className="IonPadding">
          <IonGrid className="center-content">
            <IonRow>
                <IonCol>
                  <IonRow className="ion-align-items-center">
                      <a href={`tel:${user.phone}`} style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none"
                      }}>
                        <IonIcon icon={callOutline} color="dark" style={{
                          width: "16px",
                          height: "16px",
                        }} />
                        <IonText color="dark">
                          <span style={{
                            margin: "0 24px 0 8px"
                          }}>{user.phone || 'non renseigné'}</span>
                        </IonText>
                      </a>
                      <IonIcon icon={briefcaseOutline} color="dark" style={{
                        width: "16px",
                        height: "16px",
                      }} />
                      <IonText color="dark">
                        <span style={{
                          margin: "0 0 0 8px",
                          userSelect: "none"
                        }}>{user.position || 'non renseigné'}</span>
                      </IonText>
                  </IonRow>
                </IonCol>
                <IonCol>
                  {isAdmin &&
                    <IonRow className="ion-align-items-center ion-justify-content-end">
                      <IonIcon icon={personCircleOutline} color="warning" style={{
                        width: "16px",
                        height: "16px",
                      }} />
                      <IonText color="dark">
                        <span style={{
                          color: "#ffc409",
                          margin: "0 0 0 8px",
                          userSelect: "none"
                        }}>Administrateur</span>
                      </IonText>
                    </IonRow>
                  }
                </IonCol>
              </IonRow>

              <IonRow className="ion-justify-content-between">
                <a href={`mailto:${user.email}`} style={{
                  marginTop: '20px',
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none"
                }}>
                  <IonText color="medium">
                    <span style={{
                      // margin: "0 24px 0 8px"
                    }}>{user.email}</span>
                  </IonText>
                </a>
              </IonRow>
          </IonGrid>

        </IonContent>
      }
    </IonPage>
  );
};

export default UserDetails;
