import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';

dayjs.locale(fr);

const useDate = () => {
    const formatDate = (dateValue: any, format = 'MMM D', upper = true) => {
        const date =  typeof dateValue === 'object' ? dateValue.date : dateValue;
        return dayjs(date).format(format);
    };

    const isWarning = (startDate: string, endDate: string) => dayjs(startDate).format('YYYYMMDD') <= dayjs().format('YYYYMMDD')
        && dayjs(endDate).format('YYYYMMDD') >= dayjs().format('YYYYMMDD');

    const isLate = (date: string) => dayjs(date).format('YYYYMMDD') < dayjs().format('YYYYMMDD');

    return { formatDate, isWarning, isLate };
}

export default useDate;
