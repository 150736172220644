import React, {
    createContext,
    useContext,
    FC,
    useMemo,
    useState,
    useRef,
} from 'react';
import { ToastOptions, IonToast } from '@ionic/react';
import { ReactControllerProps } from '@ionic/react/dist/types/components/createControllerComponent';
import './Toast.scss';

type ReactToastOptions = ToastOptions & Partial<ReactControllerProps>;

type ToastInstance = {
    show: (type: "success" | "error" | "", msg: string, duration?: number, options?: any) => void;
};

const ToastContext = createContext<ToastInstance | null>(null);
const { Provider } = ToastContext;

interface Props {
    value?: ToastOptions;
}

export const useToast = () => useContext(ToastContext) as ToastInstance;

export const ToastProvider: FC<Props> = ({ value, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<ReactToastOptions>();
    const [duration, setDuration] = useState<number>(2000);
    const [type, setType] = useState<string>('success');
    const ref = useRef<HTMLIonToastElement | null>(null);

    const contextValue: ToastInstance = useMemo(() => {
        const show = (typ: "success" | "error" | "", msg: string, dur: number = 2000, opts: any = {}) => {
            setDuration(dur);
            setType(typ);
            opts.message = msg;
            setOptions({
                ...value,
                ...opts,
            });
            setIsOpen(true);
        };

        return {
            show
        }
    }, [value]);

    return (
        <Provider value={contextValue}>
            {children}
            <IonToast
                ref={ref}
                isOpen={isOpen}
                cssClass={'toast-' + type}
                duration={duration}
                onDidDismiss={() => setIsOpen(false)}
                {...options}
            />
        </Provider>
    );
};
