import React, { useState } from 'react';
import './CreateSubProject.scss';
import SubProjectForm from '../../../components/SubProject/SubProjectForm';
import { IonPage, IonHeader, IonToolbar, IonContent, useIonViewWillEnter, IonGrid } from '@ionic/react';
import './CreateSubProject.scss';
import NavigationMenu from '../../../components/Header/NavigationMenu/NavigationMenu';
import TitleOptionSearch from '../../../components/Header/TitleOptionSearch/TitleOptionSearch';
import { projectService } from '../../../services';

const CreateSubProject: React.FC = () => {
  const [project, setProject]: any = useState({});
  const projectId = window.location.pathname.split('/').reverse()[2]!
  document.title = 'Créer un projet - Canreport'

  useIonViewWillEnter(() => {
    projectService.getOne(projectId)
      .then(({ data }) => {
        setProject(data)
      })
      .catch(console.log);
  }, [])

  return (
    <IonPage className="ion-page" id="main-content">
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu
            active="projets"
            backLabel={project.name}
            backLink={`/projet/${projectId}`}
          />
          <TitleOptionSearch
            title="Créer un sous-projet"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <SubProjectForm projectId={projectId} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateSubProject;
