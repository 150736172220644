import React, { useState } from "react";
import {
  IonGrid,
  IonList,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  useIonViewWillEnter,
  IonTextarea,
} from "@ionic/react";
import errorDisplayManager from "../../errorDisplayManager";
import { reloadOutline, addOutline, saveOutline } from "ionicons/icons";
import { useForm } from "react-hook-form";
import { projectService } from "../../services";
import "./ProjectForm.scss";
import { useHistory } from "react-router";
import { useToast } from "../../contexts/ToastContext";

const ProjectForm: React.FC<any> = ({ isEditing = false }) => {
  const history = useHistory();
  const toast = useToast();

  const [projectId, setProjectId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [failAttempt, setFailAttempt] = useState(false);

  const onError = (error: any) => {
    setIsSubmitting(false);
    setFailAttempt(true);
    setTimeout(() => setFailAttempt(false), 4000);
  };
  const { register, setValue, handleSubmit, errors } = useForm({
    mode: "onBlur",
  });

  useIonViewWillEnter(() => {
    if (isEditing) {
      const id = window.location.pathname.split('/').slice(-2, -1).pop()
      setProjectId(id)

      projectService.getOne(id!)
        .then(({ data }) => {
          ['name','description'].forEach(el => {
            setValue(el, data[el], {
              shouldValidate: true,
              shouldDirty: true
            })
          });
        })
        .catch(console.log);
    }
  }, [])


  const redirect = () => {
    setIsSubmitting(false);
    ['name','description'].forEach(el => {
      setValue(el, '')
    });

    history.goBack();
  };

  const onSubmit = ({ name, description }: any) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      if (isEditing) {
        projectService.put(projectId!, {
          name,
          description,
        }).then((projectResponse) => {
          toast.show('success', "Le projet a bien été modifié");
          redirect()
        })
      } else {
        projectService
          .post({
            name,
            description,
          })
          .then((projectResponse) => {
            toast.show('success', "Le projet a bien été ajouté");
            redirect();
          })
          .catch(onError);
      }
    }
  };

  return (
    <IonGrid className="form-width project-form">
      <form method="post" onSubmit={handleSubmit(onSubmit)} noValidate>
        <IonList
          style={{
            contain: "unset",
          }}
        >

          <IonItem>
            <IonLabel position="stacked">
              Nom{" "}
              <span className="error-color">
                {errorDisplayManager("name", errors)}
              </span>
            </IonLabel>
            <IonInput
              id="name"
              type="text"
              name="name"
              //@ts-ignore
              ref={
                register({
                  required: "true",
                }) as any
              }
            ></IonInput>
          </IonItem>

          <IonItem className={"ion-margin-top"}>
            <IonLabel position="stacked">
              Description
            </IonLabel>
            <IonTextarea
              placeholder="Description du projet"
              name="description"
              rows={6}
              //@ts-ignore
              ref={
                register({}) as any
              }
            ></IonTextarea>
          </IonItem>

          <IonRow
            className="ion-align-items-center"
            style={{
              margin: "20px 0",
            }}
          >
            <IonCol>
              <IonRow className="ion-align-items-center">
                {failAttempt && (
                  <p className="error-color">
                    Une erreur est survenue. Veuillez ressayer plus tard.
                  </p>
                )}
              </IonRow>
            </IonCol>
          </IonRow>
        </IonList>

        <IonRow className="ion-justify-content-between">
          <IonButton
            fill="clear"
            onClick={redirect}
          >
            <IonLabel style={{ textTransform: "none" }}>Annuler</IonLabel>
          </IonButton>

          <IonButton type="submit" disabled={isSubmitting}>
            <IonIcon
              slot="start"
              className={isSubmitting ? `rotate` : ``}
              icon={isSubmitting ? reloadOutline : isEditing ? saveOutline : addOutline}
            />
            <IonLabel style={{ textTransform: "none" }}>
              {isSubmitting ? "Chargement" : isEditing ? "Enregistrer" : "Créer"}
            </IonLabel>
          </IonButton>
        </IonRow>
      </form>
    </IonGrid>
  );
};

export default ProjectForm;
